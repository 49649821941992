import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { history } from '../_helpers/history';
import PrivateRoute from '../_components/PrivateRoute';
import HomePage from '../HomePage/HomePage';
import LoginPage from '../LoginPage/LoginPage';
import OperationsPage from '../OperationsPage/OperationsPage';
import MedicalStaffPage from '../MedicalStaffPage/MedicalStaffPage';
import HallwayPage from '../HallwayPage/HallwayPage';
import SchedulePage from '../SchedulePage/SchedulePage';
import CallCenterPage from '../CallCenterPage/CallCenterPage';
import QualityPage from '../QualityPage/QualityPage'
import config from '../config';

const App = () => {

   return (
      <div className="jumbotron">
         <div className="container">
            <div className="col-md-8 offset-md-2">
               <Router history={history}>
                  <Switch>
                     <PrivateRoute exact path="/" component={HomePage} />
                     <PrivateRoute exact path="/operaciones" component={OperationsPage} />
                     <PrivateRoute exact path="/operaciones/pasillo" component={HallwayPage} />
                     <PrivateRoute exact path="/personal-medico" component={MedicalStaffPage} />
                     <PrivateRoute exact path="/pasillo" component={HallwayPage} />
                     <PrivateRoute exact path="/agenda" component={SchedulePage} />
                     <PrivateRoute exact path="/call-center" component={CallCenterPage} />
                     <PrivateRoute exact path="/calidad" component={QualityPage} />
                     <PrivateRoute exact path="/whatsapp" component={
                        () => <iframe
                              style={{ border: 0 }}
                              src={config.whatsappToken}
                              title="Whatsapp"
                              width="100%"
                              height={1500}
                           />
                     } />
                     <Route path="/login" component={LoginPage} />
                     <Redirect from="*" to="/" />
                  </Switch>
               </Router>
            </div>
         </div>
      </div>
   );
};

export default App;