import React, { useEffect, useState, useRef } from 'react';
import {
    Spin,
    Card,
    Row,
    Col,
    Modal,
    Table,
    Tag,
    message
} from 'antd';
import { hallwayService } from '../../_services/hallway.service';

const TIMEOUT = 15000;

const MedicalConsultation = ({ date, selectedBranchOffice }) => {
    const columns = [
        {
            title: 'Nombres',
            dataIndex: 'names',
            key: 'names',
        },
        {
            title: 'Apellidos',
            dataIndex: 'last_name',
            key: 'last_name'
        },
        {
            title: 'Rut',
            dataIndex: 'rut',
            key: 'rut'
        },
        {
            title: 'Prestaciones',
            dataIndex: 'exams',
            key: 'exams'
        },
    ];

    const [dataSourceModal, setDataSourceModal] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [countPatientsWaitingForMedicalConsultation, setCountPatientsWaitingForMedicalConsultation] = useState();
    const [countPatientsWithMedicalConsultation, setCountPatientsWithMedicalConsultation] = useState();
    const [loadingMedicalConsultation, setLoadingMedicalConsultation] = useState(false);

    const fetchMedicalConsultationTotal = async () => {
        setLoadingMedicalConsultation(true);
        const response = await hallwayService.getMedicalConsultationTotal(getParams());
        setCountPatientsWaitingForMedicalConsultation(response.countPatientsWaitingForMedicalConsultation[0].count);
        setCountPatientsWithMedicalConsultation(response.countPatientsWithMedicalConsultation[0].count);
        setLoadingMedicalConsultation(false);
    }

    const openReport = async (patient) => {
        const response = await hallwayService.getMedicalReport(patient.medical_id);
        if (response.link === '/documents/original/missing.png') {
            message.info('Informe pendiente de firma');
        } else {
            window.open(response.link, '_blank');
        }
    }

    const fetchPatientsWithMedicalConsultation = async () => {
        setLoadingModal(true);
        const response = await hallwayService.getPatientsWithMedicalConsultation(getParams());
        let patients = {};

        response.patientsWithMedicalConsultation.map(patient => {
            if (patients[patient.id]) {
                patients[patient.id]['exams'].push(<Tag onClick={() => {
                    openReport(patient);
                }}>{patient.exam_name}</Tag>)
            } else {
                patients[patient.id] = patient;
                patients[patient.id]['exams'] = [<Tag onClick={
                    () => {
                        openReport(patient);
                    }
                }>{patient.exam_name}</Tag>];
            }
        })

        setDataSourceModal(Object.values(patients));

        setLoadingModal(false);
    }

    const fetchPatientsWaitingForMedicalConsultation = async () => {
        setLoadingModal(true);
        const response = await hallwayService.getPatientsWaitingForMedicalConsultation(getParams());
        setDataSourceModal(response.patientsWaitingForMedicalConsultation);
        setLoadingModal(false);
    }
		
    const intervalRef = useRef(null)

    useEffect(() => {
       fetchMedicalConsultationTotal()
       if (!intervalRef.current) { 
          intervalRef.current = setInterval(async () => {
          await fetchMedicalConsultationTotal()
       }, TIMEOUT);
       }
       return () => {
          if(intervalRef.current) {
             clearInterval(intervalRef.current)
             intervalRef.current = null
          }
       };     
    }, [selectedBranchOffice])

    const getParams = () => {
        const params = {
            date: date.format('YYYY-MM-DD')
        }

        if (selectedBranchOffice) {
            params['selectedBranchOffice'] = selectedBranchOffice
        }

        return params;
    }

    const handleCancel = () => {
        setVisibleModal(false);
    }

    const handleOk = () => {
        setVisibleModal(false);
    }

    const showModal = async (state) => {
        setVisibleModal(true);

        if (state === "WAITING") {
            fetchPatientsWaitingForMedicalConsultation();
        } else if (state === "ATTENDED") {
            fetchPatientsWithMedicalConsultation();
        }
    }

    return (
        <div><Modal
            title="Consulta Médica"
            visible={visibleModal}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
        >
            <Spin spinning={loadingModal}>
                <Table className="cmt-table-list" dataSource={dataSourceModal} columns={columns} />
            </Spin>
        </Modal>
            <Card
                title={<strong>Consulta médica</strong>}>
                {
                    <Spin spinning={loadingMedicalConsultation}>
                        <Row>
                            <Col>
                                <Card
                                    onClick={
                                        () => {
                                            showModal("WAITING");
                                        }
                                    }
                                    style={{ color: '#DD5E32', cursor: 'pointer' }}>
                                    <Row>
                                        <Col style={{ textTransform: 'uppercase', fontSize: '1.3em' }}>en espera</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                                            {countPatientsWaitingForMedicalConsultation}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            pacientes
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col style={{ marginLeft: 20 }}>
                                <Card style={{ color: '#5FB09C', cursor: 'pointer' }} onClick={() => {
                                    showModal("ATTENDED");
                                }}>
                                    <Row>
                                        <Col style={{ textTransform: 'uppercase', fontSize: '1.5em' }}>
                                            atendidos
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                                            {countPatientsWithMedicalConsultation}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            pacientes
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                }
            </Card></div>
    )
}

export default MedicalConsultation;