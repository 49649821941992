import authHeader from '../_helpers/auth-header';
import config from '../config';
import handleResponse from './handle_response';

export const callCenterService = {
  getCalls,
  getGroupIndicators,
  getIndicatorsByAgent,
  getCallsByAgent,
  getCallDetails,
  getCallsDistribution,
  getMonthlyComparative
};

function getCalls(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/calls`);
  //   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}


function getGroupIndicators(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/groupIndicators`);
  //   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getIndicatorsByAgent(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/indicatorsByAgent`);
  //   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}


function getCallsByAgent(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/callsByAgent`);
  //   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}


function getCallDetails(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/callDetails`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}


function getCallsDistribution(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/callsDistribution`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getMonthlyComparative(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const url = new URL(`${config.apiUrl}/dashboard/callCenter/monthlyComparative`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}