import { DatePicker, PageHeader, Select, Row, Col, Form, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SubHeader from './components/SubHeader';
import General from './components/General'
import Resumen from './components/Resumen'
import { operationsService } from '../_services/operations.service';
import { dashboardService } from '../_services/dashboard.service';

const { RangePicker } = DatePicker;
const endDate = moment()
const startDate = moment().subtract(7, 'day'); 

const OperationsPage = () => {
  const [actualTab, setActualTab] = useState('general');
  const [dates, setDates] = useState([startDate, endDate]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [loading, setLoading] = useState({ branchOffices: false, batteriesAndExams: false, patients: false, 
    companies: false, reservedAmount: false, attendedAmount: false, invoicedAmount: false, agreementAmount: false });
  const [selectedBranchOffices, setSelectedBranchOffices] = useState();
  const [filteredBranchOffices, setFilteredBranchOffices] = useState([]);
  const [prestationsData, setPrestationsData] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [reservedAmount, setReservedAmount] = useState();
	const [attendedAmount, setAttendedAmount] = useState();
	const [invoicedAmount, setInvoicedAmount] = useState();
	const [agreementAmount, setAgreementAmount] = useState();

  const fetchBranchOffices = async () => {
    setLoading(prevLoading => ({ ...prevLoading, branchOffices: true }));
    try {
      const response = await dashboardService.getBranchOffices();
      setBranchOffices(response);
      setFilteredBranchOffices(response);
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de sucursales');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, branchOffices: false }));
    }
  }

  const fetchBatteriesAndExamsCount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, batteriesAndExams: true }));
    try {
      const response = await operationsService.batteriesAndExamsCount(params);
      setPrestationsData(response);
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar las prestaciones');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, batteriesAndExams: false }));
    }
  }

  const fetchPatientsCount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, patients: true }));
    try {
      const response = await operationsService.getPatientsCount(params);
      setPatientsData(response);
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de pacientes');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, patients: false }));
    }
  }

  const fetchCompaniesCount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, companies: true }));
    try {
      const response = await operationsService.getCompaniesCount(params);
      setCompaniesData(response);
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de empresas');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, companies: false }));
    }
  }

  const fetchAmountByTypes = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, agreementAmount: true }));
		try {
      const response = await dashboardService.getAmountByType(params);
      if (response.length > 0)  {
        setAgreementAmount(response.filter((item) => {
          return item.payment_method == 'CONVENIO'
        })[0].sum);
      } else {
        setAgreementAmount(0);
      }
		} catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de empresas');
		} finally {
      setLoading(prevLoading => ({ ...prevLoading, agreementAmount: false }));
    }
	}

	const fetchReservedAmount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, reservedAmount: true }));
    try {
		  const [response] = await operationsService.getReservedAmount(params);
		  if (response.sum != null) {
			  setReservedAmount(response.sum);
		  } else {
			  setReservedAmount(0);
		  } 
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de empresas');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, reservedAmount: false }));
    }
	}

	const fetchAttendedAmount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, attendedAmount: true }));
    try {
      const [response] = await operationsService.getAttendedAmount(params);
      if (response.sum != null) {
        setAttendedAmount(response.sum);
      } else {
        setAttendedAmount(0);
      }
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de asistidos');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, attendedAmount: false }));
    }
	}

	const fetchInvoicedAmount = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, invoicedAmount: true }));
    try {
      const [response] = await operationsService.getInvoicedAmount(params);
      if (response.sum) {
        setInvoicedAmount(response.sum);
      } else {
        setInvoicedAmount(0);
      } 
    } catch (error) {
      console.error((`Response error: ${error}`))
      message.error('Ha ocurrido un error al buscar la información de facturados');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, invoicedAmount: false }));
    }
	}

  const handleSubmit = async (values) => {
    let params = {};

    if (values.dates) {
      params.startDate = values.dates[0].format('YYYY-MM-DD'),
      params.endDate = values.dates[1].format('YYYY-MM-DD')
      setDates(values.dates);
    } else {
      params.startDate = dates[0].format('YYYY-MM-DD')
      params.endDate = dates[1].format('YYYY-MM-DD')
    }
      
    if (values.branch_offices) {
      let branchOfficeNames = values.branch_offices
      let branchOfficeSearch = branchOffices.filter(branchOffice =>
        branchOfficeNames.includes(branchOffice.name)
      )
      let branchOfficeIds = branchOfficeSearch.map(branchOffice => branchOffice.id)
      params['selectedBranchOffices'] = branchOfficeIds;
      setSelectedBranchOffices([branchOfficeIds]);
    }

    fetchBatteriesAndExamsCount(params);
    fetchPatientsCount(params);
    fetchCompaniesCount(params);
    fetchAmountByTypes(params);
    fetchReservedAmount(params);
    fetchAttendedAmount(params);
    fetchInvoicedAmount(params);
  };

  const handleSearch = (value) => {
    const filteredBranchOffices = branchOffices.filter(
      branchOffice => branchOffice.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setFilteredBranchOffices(filteredBranchOffices);
  }
  
  useEffect(() => {
    fetchBranchOffices();
  }, [])

  useEffect(() => {
    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD')
    }
    fetchBatteriesAndExamsCount(params);
    fetchPatientsCount(params);
    fetchCompaniesCount(params);
    fetchAmountByTypes(params);
    fetchReservedAmount(params);
    fetchAttendedAmount(params);
    fetchInvoicedAmount(params);
  }, [])
   
   return (
      <PageHeader
         style={{ 'backgroundColor': 'white' }}
         title="Operaciones" 
         className="cmt-form"
         extra={[
            <Form layout='vertical' style={{ width: 550 }} onFinish={handleSubmit}>
               <Row gutter={10}>
                  <Col lg={12} xs={24}>
                     <div style={{ width: '100%' }}>
                        <Form.Item label="Fechas" name="dates">
                          <RangePicker
                            style={{ width: '100%' }}
                            className="cmt-date-picker"
                            size="large"
                            defaultValue={dates} />
                        </Form.Item>
                     </div>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item label='Sucursal' name="branch_offices">
                      <Select
                        className="cmt-select"
                        size="large"
                        style={{ width: "100%", display: "block" }}
                        mode="multiple"
                        placeholder="Seleccionar Sucursal"
                        onSearch={handleSearch}
                      >
                        {filteredBranchOffices?.map((branchOffice) => (
                          <Select.Option value={branchOffice.name} key={branchOffice.id}>
                            {branchOffice.name}
                          </Select.Option>
                        ))}
                      </Select>
                     </Form.Item>
                  </Col>
                  <Col lg={4} xs={24} style={{ width: '100%' }}>
                     <Form.Item label=" ">
                        <Button 
                           className="cmt-button primary"
                           size="large"
                           type="primary"
                           htmlType="submit"
                        >
                           Filtrar
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
      ]}
      >
        <Row>
          <h1>Fecha del {dates[0].format('DD-MM-YYYY')} al {dates[1].format('DD-MM-YYYY')}</h1>
        </Row>
        <Row className="operations-page mt-20">
			    <Col lg={24} xs={24}>
            <SubHeader 
                setActualTab={setActualTab}
            />
            {(actualTab === 'general')
            ? <General
                actualTab={actualTab}
                dashboardService={dashboardService}
                branchOffices={branchOffices}
                selectedBranchOffices={selectedBranchOffices}
                dates={dates}
                prestationsData={prestationsData}
                patientsData={patientsData}
                companiesData={companiesData}
                reservedAmount={reservedAmount}
                attendedAmount={attendedAmount}
                invoicedAmount={invoicedAmount}
                agreementAmount={agreementAmount}
                loading={loading}
              />
            : <Resumen
                actualTab={actualTab}
                dashboardService={dashboardService}
                branchOffices={branchOffices}
                selectedBranchOffices={selectedBranchOffices}
                dates={dates}
              />
            }
          </Col>
        </Row>
      </PageHeader>
   )
}

export default OperationsPage;