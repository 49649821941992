import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PageHeader} from 'antd';
import { qualityService } from '../_services/quality.services'
import { dashboardService } from '../_services/dashboard.service';
import SubHeader from './components/SubHeader';
import Filters from './components/Filters';

const QualityPage = () => {
  const [actualTab, setActualTab] = useState('branchOffice');
  const startDate = moment().subtract(15, 'day').format('YYYY-MM-DD'); 
  const endDate = moment().format('YYYY-MM-DD');
 
  const [filter, setFilter] = useState({
    startDate: startDate,
    endDate: endDate,
    selectedBranchOffices: '',
    prestation: '',
    companyName: '',
    companyId: ''
  });  

  return (
    <PageHeader
      className='page-header'
    >
      <SubHeader 
        setActualTab={setActualTab}
        filter={filter}
      />
      <Filters 
        actualTab={actualTab}
        qualityService={qualityService}
        filter={filter}
        setFilter={setFilter}
        dashboardService={dashboardService}
      />
    </PageHeader>
  )
}
export default QualityPage;