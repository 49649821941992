import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies';

function getGeneralData(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/general`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getRanking(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };
  const url = new URL(`${config.apiUrl}/dashboard/commercial/ranking`);

	if (params) {
  	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	}
  return fetch(url, requestOptions).then(handleResponse);
}

function getRankingModal(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };
  const url = new URL(`${config.apiUrl}/dashboard/commercial/rankingModal`);

  if (params) {
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	}
  return fetch(url, requestOptions).then(handleResponse);
}

function getRankingPieCharts(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };
  const url = new URL(`${config.apiUrl}/dashboard/commercial/rankingPieCharts`);

  if (params) {
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	}  
	return fetch(url, requestOptions).then(handleResponse);
}

function getBranchOffices(params) {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/branchOffices`);
  
  if (params) {
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	}

  return fetch(url, requestOptions).then(handleResponse);
}

function getDataComparativo(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/dataComparativo`);
  Object.keys(params).forEach(key => {
    if (key != 'selectedBranchOffices') {

      url.searchParams.append(key, params[key])
    } else {
      if (params[key] != undefined && params[key] != 'undefined') {
        url.searchParams.append(key, params[key]);
      }
    }
  }
  )
  return fetch(url, requestOptions).then(handleResponse);
}

function getAmountByType(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };
  const url = new URL(`${config.apiUrl}/dashboard/commercial/amountByType`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const getBillsByUnpaid = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  }
  const url = new URL(`${config.apiUrl}/dashboard/commercial/billsByUnpaid`);
  return fetch(url, requestOptions).then(handleResponse);
};

const getPendingToBill = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  }
  const url = new URL(`${config.apiUrl}/dashboard/commercial/pendingToBill`);
  return fetch(url, requestOptions).then(handleResponse);
};

const getCurrentMonth = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  }
  const url = new URL(`${config.apiUrl}/dashboard/commercial/currentMonth`);
  return fetch(url, requestOptions).then(handleResponse);
};

const getCompanies = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  }
  const url = new URL(`${config.apiUrl}/dashboard/commercial/companies`);
  Object.keys(params).forEach(key => params[key] && url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
};

export const dashboardService = {
  getGeneralData,
  getRanking,
	getRankingModal,
	getRankingPieCharts,
  getBranchOffices,
  getDataComparativo,
  getAmountByType,
  getBillsByUnpaid,
  getPendingToBill,
  getCurrentMonth,
  getCompanies,
};