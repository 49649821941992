import moment from 'moment';

export function dateRanges() { 
  const dateRangeKeys = 
    {
      'last15Days': 'Últimos 15 días', 
      'last30Days': 'Últimos 30 días',
      'last60Days': 'Últimos 60 días',
      'last90Days': 'Últimos 90 días'
    };

  return {
    [dateRangeKeys['last15Days']]: [moment().subtract(15, 'day'), moment()],
    [dateRangeKeys['last30Days']]: [moment().subtract(30, 'day'), moment()],
    [dateRangeKeys['last60Days']]: [moment().subtract(60, 'day'), moment()],
    [dateRangeKeys['last90Days']]: [moment().subtract(90, 'day'), moment()]
  };
};

export function dateRangePlaceholder() {
  const ranges = dateRanges();
  return [
    ranges['Últimos 15 días'][0].format('DD-MM-YYYY'),
    ranges['Últimos 15 días'][1].format('DD-MM-YYYY')
  ]
};