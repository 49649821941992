import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { dashboardService } from '../_services/dashboard.service';
import { medicalStaffService } from '../_services/medicalStaff.service';
import { getCookie } from '../_services/cookies'
import {
   Form, Card, Row, Col, Table, DatePicker, Select, PageHeader,
   Spin, Checkbox
} from 'antd';
import Blocks from './components/Blocks';

const columns = [
   {
      title: 'Nº',
      key: 'index',
      render: (value, item, index) => index + 1
   },
   {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
   },
   {
      title: 'Atenciones Promedio',
      dataIndex: 'avg',
      key: 'avg',
      align: 'right',
      render: (value, item, index) => <span style={{ float: 'right' }}>{Math.round(Number(value) * 10) / 10}</span>
   }
];

const MedicalStaffPage = () => {
   const defaultDate = moment()
   const [branchOffices, setBranchOffices] = useState();
   const [selectedBranchOffices, setSelectedBranchOffices] = useState(
      getCookie('country_code') === 'CL' ? 1 : 21
   );
   const [doctors, setDoctors] = useState();
   const [loading, setLoading] = useState();
   const [date, setDate] = useState(defaultDate);
   const [checkedMonth, setCheckedMonth] = useState(false);
   const [ranking, setRanking] = useState();

   const changeDate = (date) => {
      setDate(date)
   }

   const getParams = () => {
      const params = {
         date: date.format('YYYY-MM-DD'),
         checkedMonth: checkedMonth
      }

      if (selectedBranchOffices) {
         params['selectedBranchOffices'] = selectedBranchOffices
      }

      return params;
   }

   const handleBranchOffices = (selected) => {
      setSelectedBranchOffices(selected);
   }

   const fetchBranchOffices = async () => {
      const response = await dashboardService.getBranchOffices(getParams());
      setBranchOffices(response.map(obj => <Select.Option value={obj.id} key={obj.id}>{obj.name}</Select.Option>));
   }

   const fetchDoctors = async () => {
      setLoading(true);
      const response = await medicalStaffService.getStaff(getParams());
      setDoctors(response.staff);
      setLoading(false);
   }

   const fetchStaffRanking = async () => {
      const response = await medicalStaffService.getStaffRanking(getParams());
      setRanking(response.ranking);
   }

   useEffect(() => {
      fetchDoctors();
   }, [selectedBranchOffices])

   useEffect(() => {
      fetchBranchOffices();
      fetchDoctors();
   }, [])

   useEffect(() => {
      fetchStaffRanking();
   }, [selectedBranchOffices, date]);

   const switchAllMonth = (e) => {
      setCheckedMonth(e.target.checked)
   }

   let timePicker;

   if (checkedMonth) {
      timePicker = <DatePicker
        className="cmt-date-picker" 
        size="large"
        picker="month"
        onChange={changeDate}
        defaultValue={date}
        value={date}
        allowClear={false} 
      />
   } else {
      timePicker = <DatePicker
        className="cmt-date-picker" 
        size="large"
        onChange={changeDate}
        defaultValue={date}
        value={date}
        allowClear={false} 
      />

   }

   return (
      <PageHeader
         style={{ 'backgroundColor': 'white' }}
         title="Personal Médico"
         extra={[
            <Form layout="vertical" style={{ width: 550 }}>
               <Row gutter={8}>
                  <Col lg={8} style={{ width: '100%' }}>
                     <Form.Item label='Sucursal'>
                        <Select
                           size="large"
                           style={{ marginRight: 10 }}
                           onChange={handleBranchOffices}
                           placeholder='Seleccionar Sucursal'
                           defaultValue={selectedBranchOffices}
                           className="cmt-select"
                        >
                           {branchOffices}
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col lg={8}>
                     <Form.Item label='Fecha'>
                        {timePicker}
                     </Form.Item>
                  </Col>
                  <Col lg={8}>
                     <Form.Item label=' ' className="Item">
                        <Checkbox onChange={switchAllMonth}>Mes completo</Checkbox>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
         ]}
      >
         <Row>
            <Col lg={16}>
               <Spin spinning={loading}>
                  {
                     doctors && doctors.map(doctor => {
                        return (
                           <div>
                              <Blocks
                                 checkedMonth={checkedMonth}
                                 selectedBranchOffices={selectedBranchOffices}
                                 date={date}
                                 doctor={doctor}
                                 key={doctor.id}
                              />
                           </div>
                        )
                     })
                  }
               </Spin>
            </Col>
            <Col lg={8}>
               <div style={{ padding: "1em", paddingTop: '0.8em' }}>
                  <Card title='Ranking'>
                     {ranking &&
                        <Table className="cmt-table-list" columns={columns} dataSource={ranking} />
                     }
                  </Card>
               </div>

            </Col>
         </Row>
      </PageHeader>
   );
};

export default MedicalStaffPage;