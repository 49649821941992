import React, { useState, useEffect } from 'react';
import { Table, Spin, message } from 'antd';
import { formatAmountWithCurrency } from '../../_helpers/formatAmount';
import { dashboardService } from '../../_services/dashboard.service';

const RankingModalTable = ({ ranking, dates, limit, rankingType, selectedBranchOffices, isModalOpen }) => {
  const [rankingModal, setRankingModal] = useState();
	const [loading, setLoading] = useState(false);

	const fetchRankingModal = async () => {
		setLoading(true);
    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      limit: limit,
      type: rankingType
    }

    if (selectedBranchOffices) {
      params.selectedBranchOffices = selectedBranchOffices.join();
    }

    if (ranking?.length > 0) { 
      params.company = ranking[0].businessName;
      params.id = ranking[0].id;
    }

		try {
			const response = await dashboardService.getRankingModal(params);
			setRankingModal(response);
		} catch (error) {    
    	console.error(error);
			message.error('Ha ocurrido un error al buscar la información');
  	} finally {
 			setLoading(false);
		}
  }

	useEffect(() => {
		if (isModalOpen) {
			fetchRankingModal();
		}
	}, [ranking]);

  return (
		<Spin spinning={loading} className="cmt-spin">
			<h1 className="modal-title mb-30"><strong>{ranking[0].businessName}</strong></h1>
			<h4 className="mb-10 mr-50" style={{ float: 'right' }}>Fechas de {dates[0].format('YYYY-MM-DD')} a {dates[1].format('YYYY-MM-DD')}</h4>
			<Table
				style={{ width: '100%', border: '1px solid #F1F1F1' }}
				dataSource={rankingModal}
				columns={getColumns()}
				pagination={false}
				className="cmt-table-list"
			/>
		</Spin>
  )
}

const getColumns = () => {
  return [
    {
      title: 'Consumo',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (value) => {
        return (
          <span>
            {formatAmountWithCurrency(value)}
          </span>
        );
      },
      width: '125px'
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch_office_name',
      key: 'branch_office_name',
      render: (value, record) => {
        return (
          <span>
            {value}
          </span>
        );
      }
    },
    {
      title: 'Prestación',
      dataIndex: 'prestations',
      key: 'prestations',
      render: (value) => {
        return (
          <span>
            {
							value?.map((prestation, index) => {
                return prestation.count + ' ' + prestation.service_name + (index !== value.length - 1 ? `, ` : '')
              })
            }
          </span>
        );
      }
    },
  ]
}

export default RankingModalTable;