import React, { useState, useEffect } from 'react';
import { Table, Spin, Card, Select, Form, Row, Col, Button, Modal, message, Popover } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { dashboardService } from '../../_services/dashboard.service';
import RankingModalTable from './RankingModalTable';
import RankingPieCharts from './RankingPieCharts';
import { formatAmount, formatAmountWithCurrency } from '../../_helpers/formatAmount';
import { getCookie } from '../../_services/cookies';
import fetchCompanyNames from '../../_helpers/fetchCompanyNames';

const Ranking = ({ dates, selectedBranchOffices }) => {
  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [rankingType, setRankingType] = useState('activity');
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState({
    company: '',
    id: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null)
	const [isPieOpen, setIsPieOpen] = useState(false);
  const [removedFilters, setRemovedFilters] = useState(false)
  const [form] = Form.useForm();
  const [dropDownName, setDropDownName] = useState('');

  const showModal = (record) => {
    setSelectedRecord([record])
    setIsModalOpen(true);
  };

  const showPieChart = () => {
    setIsPieOpen(true);
  };

  useEffect(() => {
    fetchRanking();
  }, [
    dates,
    limit,
    selectedBranchOffices,
    rankingType,
    removedFilters,
  ])

  const getColumns = () => {
    return [
      {
        title: 'Empresa',
        dataIndex: 'businessName',
        key: 'businessName',
        render: (text, record) => (
          <>
            <Button className="no-background-button" onClick={() => showModal(record)}>
              <strong>
                <u>{text}</u>
              </strong>
            </Button>
          </>
        ),
      },
      {
        title: (
          <strong> Valor 
            <Popover 
              trigger="hover"
              overlayClassName="sidebar-tooltip"
              placement="right"
              content={
                <span>
                  Consumo de la empresa en el período de tiempo seleccionado
                </span>
              }
            >  
              <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
            </Popover>
          </strong>
        ),
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'right',
        render: (value) => {
          const formattedValue = formatAmountWithCurrency(value)
          return (
            <span style={{ float: 'right' }}>
              {formattedValue}
            </span>
          );
        }
      },
      {
        title: 'Tipo Cliente',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        align: 'right',
        render: (value) => {
          if (value == "OC PAGO 30 DÍAS") {
            return (<span>OC pago 30 días</span>)
          }
          else return (
            <span>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</span>
          )
        }
      },
      {
        title: (item) => {
          if (rankingType === 'prestationCount') {
            return (
              <strong> Prestaciones 
                <Popover 
                  trigger="hover"
                  overlayClassName="sidebar-tooltip"
                  placement="right"
                  content={
                    <span>
                      Total de prestaciones por empresa
                    </span>
                  }
                >  
                  <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
                </Popover>
              </strong>
            )
          } else if (rankingType === 'averageTicket') {
            return 'Ticket Promedio';
          } else if (rankingType === 'patientCount') {
            return 'Pacientes';
          } else if (rankingType === 'prestationsPatientRatio') {
            return 'Prestaciones/Paciente';
          }
          return ''
        },
        dataIndex: 'second_column',
        key: 'second_column',
        align: 'right',
        render: (value, row, index) => {
          return (
            <div style={{ float: 'right' }}>
              {
                !loading && rankingType === 'averageTicket' &&
                <span style={{ float: 'right' }}>
                  {formatAmountWithCurrency(row.averageTicket)}
                </span>
              }

              {
                !loading && rankingType === 'prestationCount' && <div>{row.prestationCount}</div>
              }

              {
                !loading && rankingType === 'patientCount' && <div>{row.patientCount}</div>
              }

              {
                !loading && rankingType === 'prestationsPatientRatio' && <div>{formatAmount(parseFloat(row.prestationsPatientRatio).toFixed(1))}</div>
              }
            </div>
          )
        }
      },
    ];
  }

  const handleLimit = (limit) => {
    setLimit(limit);
  }

  const handleRanking = (rankingType) => {
    setRankingType(rankingType);
    setDropDownName(rankingType);
  }

  const fetchRanking = async () => {
    setLoading(true);

    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      limit: limit,
      type: rankingType
    }

    if (selectedBranchOffices) {
      params["selectedBranchOffices"] = selectedBranchOffices.join();
    }

    if (companies?.length > 0) { 
      params.company = filter.company;
      params.id = filter?.id;
    }

    try {
      const response = await dashboardService.getRanking(params);
      setRanking(response);
    }
    catch (error) {
      console.error(error);
      message.error('Ha ocurrido un error al buscar las empresas');
    }
    setLoading(false);
  }

  const getCompanyNames = async (value) => {
    try {
      const response = await fetchCompanyNames(value);
      setCompanies(response?.map(obj => <Select.Option value={obj.business_name} key={obj.id}> {obj.business_name} </Select.Option>));
    } catch (error) {
      console.error(error);
      message.error('Ha ocurrido un error al buscar las empresas');
    }
  }

  const handleChangeCompanies = (value, id) => {
    setFilter({
      company: value ? value.map(obj => obj) : '',
      id: id ? id.map(obj => (obj.key)).join(',') : '',
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const removeFilters = () => {
    form.resetFields();
    setFilter({
      company: '', 
      id: ''
    })
    setLimit(10);
    setRankingType('activity');
    setDropDownName('');
    setRemovedFilters(prev => !prev);
  };
  
  const COLORS = ['#F29877', '#4F5970', '#BDB3A0', '#AAC1F2', '#F7DF95', '#06789D'];

  return (
    <Card title={
      <Row className="mt-10">
        <Col lg={17}>
          <h4>Top Empresas</h4>
        </Col> 
        <Col lg={7}>
          <h4>Fecha del {dates[0].format('DD-MM-YYYY')} al {dates[1].format('DD-MM-YYYY')}</h4>
        </Col>
      </Row>
    }
      className="mt-40"
    >
    <Form
      form={form}
      className="cmt-form"
    >
      <Row align="bottom">
        <Col lg={6} xs={24} className="form-wrapper mr-10">
          <Form.Item label="Búsqueda por Empresa" name="company" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Select
              className="cmt-select"
              size="large"
              style={{ width: "100%", display: "block" }}
              mode="multiple"
              placeholder={getCookie('country_code') === 'CL' ? "Nombre o RUT" : "Nombre o DNI"}
              onChange={handleChangeCompanies}
              onSearch={getCompanyNames}
            >
              {companies}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={3} xs={24} className="mr-100">
          <Form.Item>
            <Button
              size="large"
              className="cmt-button primary"
              type="primary"
              htmlType="button"
              onClick={() => {
                fetchRanking();
              }}
            >
              Filtrar
            </Button>
          </Form.Item>
        </Col>
        <Col lg={4} xs={12}>
          <Form.Item
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Búsqueda por
                  <Popover
                    trigger="hover"
                    overlayClassName="sidebar-tooltip"
                    placement="right"
                    content={
                      <span>
                        Selecciona el top de empresas que registraron más ventas en el periodo de tiempo seleccionado
                      </span>
                    }
                  >
                    <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
                  </Popover>
              </div>
            }
            labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
            name="Top de empresas"
          >
            <Select className="cmt-select" style={{ width: "70%" }} placeholder="10" defaultValue={10} onChange={handleLimit}>
              <Select.Option value={10}>Top 10</Select.Option>
              <Select.Option value={20}>Top 20</Select.Option>
              <Select.Option value={30}>Top 30</Select.Option>
              <Select.Option value={40}>Top 40</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item label="Ordenar por" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Select className="cmt-select" style={{ width: "80%" }} value={dropDownName} onChange={handleRanking}>
              <Select.Option value="averageTicket">Ticket Promedio</Select.Option>
              <Select.Option value="prestationCount">Prestaciones</Select.Option>
              <Select.Option value="patientCount">Número de Pacientes</Select.Option>
              <Select.Option value="prestationsPatientRatio">Prestaciones/Paciente</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={2} xs={4}>
          <Form.Item>
            <Button
              onClick={removeFilters}
              className="cmt-button primary non-outline"
              type="primary"
              size="small"
            >
              Borrar filtros
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form> 
      <Spin spinning={loading} className="cmt-spin">
        <Table rowkey={(record) => record.key}
          style={{ width: '100%', overflowX: 'auto' }}
          dataSource={ranking}
          columns={getColumns()}
          expandable={{
            expandedRowRender: (record) => (
							<Row justify="center">
								<RankingPieCharts  
									id={record.id}
									dates={dates}
									limit={limit}
									rankingType={rankingType}
									isPieOpen={isPieOpen}
									selectedBranchOffices={selectedBranchOffices}
								/>
							</Row>
            ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <span
                onClick={(e) => {
									onExpand(record, e) 
									showPieChart(record)
								}}
              >
                {expanded ?
                  <i className="icon-system-chevron-down" /> :
                  <i className="icon-system-chevron-right" />
                }
              </span>
            ),
          }}
          pagination={false}
          className="cmt-table-list"
        />
 				<Modal
					className="ranking-modal"
					responsive
					centered
					cancelText="cerrar"
					open={isModalOpen}
					onCancel={handleCancel}
					maskClassName="ranking-mask"
					footer={false}>
						<RankingModalTable
							ranking={selectedRecord}
							dates={dates}
							limit={limit}
							rankingType={rankingType}
							isModalOpen={isModalOpen}
							selectedBranchOffices={selectedBranchOffices}/>
				</Modal>
      </Spin>
    </Card>
  );
};

export default Ranking;