import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies';

export const hallwayService = {
	getTotalAdmission,
	getTotalPatientsWithAdmission,
	getPatientsWithExams,
	getMedicalConsultationTotal,
	getPatientsWithMedicalConsultation,
	getPatientsWaitingForMedicalConsultation,
	getExams,
	getMedicalReport,
};

function getTotalAdmission(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/totalAdmission`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getTotalPatientsWithAdmission(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/patientsWithAdmission`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}


function getPatientsWithExams(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/patientsWithExams`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}


function getMedicalConsultationTotal(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/medicalConsultationTotal`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}


function getPatientsWithMedicalConsultation(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/patientsWithMedicalConsultation`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}


function getPatientsWaitingForMedicalConsultation(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/patientsWaitingForMedicalConsultation`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getExams(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/dashboard/operations/exams`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}


function getMedicalReport(medical_id) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};

	const url = new URL(`${config.apiUrl}/report/?medical_consultation_id=${medical_id}`);
	return fetch(url, requestOptions).then(handleResponse);
}

