import { Spin, Row, Col, Table } from "antd";
import { formatAmount } from "../../_helpers/formatAmount";

const qualityTableSummary = ({ actualTab, summaryDataSource, loading }) => {

  const renderPendingSignatureCondition = (record) => {
    if (
      record?.pendingSignature?.props?.children === 0 ||
      record?.pendingSignature === "0"
    ) {
      return <strong>0%</strong>;
    }
    if (record?.pendingSignature?.props?.children) {
      return (
        <strong>
          {formatAmount(
            (
              (record?.pendingSignature?.props?.children /
                record?.reports?.props?.children) *
              100
            ).toFixed(1)
          )}
          %
        </strong>
      );
    }
    if (record?.pendingSignature) {
      return (
        <strong>
          {formatAmount(
            ((record.pendingSignature / record?.reports) * 100).toFixed(1)
          )}
          %
        </strong>
      );
    }
  };

  const renderLateReportsCondition = (value, record) => {
    if (
      record?.lateReports?.props?.children === 0 ||
      record?.lateReports === "0"
    ) {
      return <strong>0%</strong>;
    }
    if (record?.lateReports?.props?.children) {
      return (
        <strong>
          {formatAmount(
            (
              (record?.lateReports?.props?.children /
                record?.reports?.props?.children) *
              100
            ).toFixed(1)
          )}
          %
        </strong>
      );
    }
    if (record?.lateReports) {
      return (
        <strong>
          {formatAmount(
            ((record.lateReports / record?.reports) * 100).toFixed(1)
          )}
          %
        </strong>
      );
    }
  };

  const columns = [
    {
      title: 
      (actualTab === "byCompany") ?
      "Empresa" : "Sucursal",
      dataIndex: "name",
      key: "name",
      width: "14%",
    },
    {
      title: (
        <div>
          Pacientes
          <br />
          <span className="span-small">
            <br />
          </span>
        </div>
      ),
      dataIndex: "patients",
      key: "patients",
      align: "center",
      width: "7%",
    },
    {
      title: (
        <div>
          Prestaciones
          <br />
          <span className="span-small">
            <br />
          </span>
        </div>
      ),
      dataIndex: "attentions",
      key: "attentions",
      align: "center",
      width: "7%",
    },
    {
      title: (
        <div>
          Pres/Pac.
          <br />
          <span className="span-small">
            <br />
          </span>
        </div>
      ),
      dataIndex: "number_services_per_patient",
      key: "number_services_per_patient",
      align: "center",
      width: "7%",
    },
    {
      title: (
        <div>
          Pend. de Firma
          <br />
          <span className="span-small">
            Pendientes/Total/<strong>%</strong>
          </span>
        </div>
      ),
      align: "center",
      width: "16%",
      render: (record) => (
        <Row>
          <Col lg={12} xs={12}>
            {record.pendingSignature}
            <span className="span-small">/{record.reports}</span>
          </Col>
          <Col lg={12} xs={12}>
            {renderPendingSignatureCondition(record)}
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <div>
          Atrasados
          <br />
          <span className="span-small">
            Atrasados/Total/<strong>%</strong>
          </span>
        </div>
      ),
      align: "center",
      width: "16%",
      render: (value, record) => (
        <Row>
          <Col lg={12} xs={12}>
            {record.lateReports}
            <span className="span-small">/{record.reports}</span>
          </Col>
          <Col lg={12} xs={12}>
            {renderLateReportsCondition(value, record)}
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <div>
          Informes Pendientes
          <br />
          <span className="span-small">
            <br />
          </span>
        </div>
      ),
      dataIndex: "pendingReports",
      key: "pendingReports",
      align: "center",
      width: "33%",
    },
  ];

  return (
    <Spin className="cmt-spin" spinning={loading.summaryTable}>
      <div className="quality-page">
        <Table
          className="cmt-table-list"
          dataSource={summaryDataSource}
          columns={columns}
          bordered={false}
          locale={{ emptyText: "No hay datos para mostrar" }}
        />
      </div>
    </Spin>
  );
};
export default qualityTableSummary;
