import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies';

export const operationsService = {
  batteriesAndExamsCount,
  getCompaniesCount,
  getPatientsCount,
  getCompaniesWithMostServices,
  getReservedAmount,
  getInvoicedAmount,
  getCompaniesWithMoreAbsencesRanking,
  getAttendedAmount,
  getPatientsChart,
  getPatientsCountByCompany
};

function batteriesAndExamsCount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/operations/batteriesAndExamsCount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientsCount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/operations/patientsCount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getCompaniesCount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/operations/companiesCount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getAttendedCompanies(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/attendedCompanies`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getCompaniesWithMostServices(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/companiesWithMostServices`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getReservedAmount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/reservedAmount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getCompaniesWithMoreAbsencesRanking(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };
  const url = new URL(`${config.apiUrl}/dashboard/commercial/companiesWithMoreAbsencesRanking`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getAttendedAmount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/attendedAmount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getInvoicedAmount(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/invoicedAmount`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getPatientsChart(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/commercial/patientsChart`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);

}

function getPatientsCountByCompany(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/operations/patientsCountByCompany`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}
