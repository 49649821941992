import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Spin, message } from 'antd';
import { operationsService } from '../../_services/operations.service';
import {
    Line, CartesianGrid, ComposedChart,
    XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts';

const Patients = ({ filterDates, selectedBranchOffices }) => {
    const [loadingChart, setLoadingChart] = useState(false);
    const [patientsChart, setPatientsChart] = useState()

    const fetchPatientsChart = async () => {
        setLoadingChart(true);
        try {
            const response = await operationsService.getPatientsChart(getParams())
            setPatientsChart(response.map(item => ({
                dayFormatted: moment(item.day).format('DD-MM-YY'),
                reservations: parseInt(item.reservations),
                attentions: parseInt(item.attentions),
            })));
        } catch (error) {
            console.error(error);
            message.error('Ha ocurrido un error al buscar la información del gráfico');
        } finally {
            setLoadingChart(false);
        }
    }

    const getParams = () => {
        let endDateCustomString = filterDates[1].format('YYYY-MM-DD');
        const endDate = moment(endDateCustomString, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const firstDate = moment(endDateCustomString, 'YYYY-MM-DD').subtract(10, 'days').format('YYYY-MM-DD');
        const params = {
            startDate: firstDate,
            endDate: endDate
        }

        if (selectedBranchOffices) {
            params['selectedBranchOffices'] = selectedBranchOffices
        }

        return params;
    }

    useEffect(() => {
      fetchPatientsChart();
  }, [filterDates, selectedBranchOffices ]);

    return (
        <Card title='Comparativo de reservas versus atenciones' className="mt-40">
            {
                <Spin spinning={loadingChart} className="cmt-spin">
                    <ResponsiveContainer height={400}>
                        <ComposedChart
                            title="Comparativo"
                            width={600}
                            height={400}
                            data={patientsChart}>
                            <CartesianGrid stroke="#eee" />
                            <Line type="monotone" dataKey="attentions" stroke="#223A70" borderWidth='5' strokeWidth={5} name="Atenciones" />
                            <Line type="monotone" dataKey="reservations" stroke="#DC582A" borderWidth='5' strokeWidth={5} name="Reservaciones" />
                            <Tooltip cursor={false} />
                            <XAxis dataKey="dayFormatted" />
                            <YAxis interval="preserveStartEnd" yAxis='left' tickCount={50} tickLink={false} axisLine={true} />
                            <Legend align="right" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Spin>
            }
        </Card>
    )
}

export default Patients;