import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Table, Spin, Card, Form, Row, Col, DatePicker } from 'antd';
import { scheduleService } from '../../_services/schedule.service';

const startDate = moment()

const ScheduleTable = ({ selectedBranchOffices }) => {
  const [date, setDate] = useState(startDate);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const disabledDate = (selectedDate) => {
    return selectedDate && selectedDate > moment().endOf('day').add(6, 'days');
  }

  const fetchScheduleTable = useCallback( async () => {
    setLoading(true);
    try {
      const response = await scheduleService.getScheduleTable(getParams());
      setData(response);
    }
    catch(error) {
      console.error((`Response error: ${error}`))
    }
    finally {
      setLoading(false);
    }
  }, [date])

  useEffect(() => {
    fetchScheduleTable();
  }, [fetchScheduleTable])

  const handleDateChange = (date) => {
	  setDate(date)
  }

  const getParams = () => {
    const params = {
        startDate: date.format('YYYY-MM-DD')
    }
    if (selectedBranchOffices) {
        params.selectedBranchOffices = selectedBranchOffices
    }

    return params;
  }

  const columns = [
    {
      title: 'Sucursal',
      dataIndex: 'branchOfficeName',
      key: 'branchOfficeName',
      render: (value, index) =>
        <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
          {value}
        </p>
    },
    {
      title: 'Total',
      children: [
        {
          title: 'Capacidad',
          dataIndex: 'totalCapacity',
          key: 'totalCapacity',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>
        },
        {
          title: 'Reservaciones',
          dataIndex: 'totalReservation',
          key: 'totalReservation',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>  
        },
      ]
    },
    {
      title: 'AM',
      children: [
        {
          title: 'Capacidad',
          dataIndex: 'capacityMorning',
          key: 'capacityMorning',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>
        },
        {
          title: 'Reservaciones',
          dataIndex: 'reservationMorning',
          key: 'reservationMorning',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>
        },
      ],
    },
    {
      title: 'PM',
      children: [
        {
          title: 'Capacidad',
          dataIndex: 'capacityAfternoon',
          key: 'capacityAfternoon',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>
        },
        {
          title: 'Reservaciones',
          dataIndex: 'reservationAfternoon',
          key: 'reservationAfternoon',
          align: 'center',
          render: (value, index) =>
            <p className={`mb-0 ${index === data[data.length - 1] && 'bold'}`}>
              {value}
            </p>
        }
      ]
    }
  ]

return (
   
  <div>
    <Form
      layout="vertical"
    >
      <Row justify='end'>
        <Col lg={3} xs={4}>
          <Form.Item name="Fecha">
            <DatePicker
              className="cmt-date-picker"
              style={{ width: '100%' }}
              size="large"
              value={date}
              placeholder={date.format('DD-MM-YYYY')}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              allowClear={false} 
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <Card title="Capacidad de agenda del día">
        <Spin spinning={loading} className='cmt-spin'>
          <Table
            style={{ width: '100%', overflowX: 'auto' }}
            dataSource={data}
            columns={columns}
            pagination={false}
            className="cmt-table-list"
          />
        </Spin>
      </Card>
  </div>
);

}
export default ScheduleTable;
