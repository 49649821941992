import moment from 'moment';
import React, { useState } from 'react';
import { Spin, Card, Row, Col } from 'antd';
import {
  ResponsiveContainer,
  Line, CartesianGrid, ComposedChart,
  XAxis, YAxis, Bar, Tooltip, Legend, LabelList
} from 'recharts';

const ScheduleChart = ({ branchOffice }) => {
  const [loading, setLoading] = useState(false);

  const CustomizedLabel = (props) => {
    const { x, y, value } = props;
    return (
      <g>
        <text
          fontSize="12px"
          x={props.viewBox.x + 3 / 2 * props.width / 4}
          y={props.viewBox.y - 20}
          fill="#333">
          {props.value}%
        </text>
      </g>
    )
  } 

  const formatXAxis = (dates) => {
    return moment(dates).format('DD/MM')
  }
  

  const labelFormatter = (pf) => {
    return String(pf) + '%'
  }

  if (branchOffice.schedules && !branchOffice.schedules.length) {
    return <div />
  }

  return (
    <Row justify="center">
      <Col lg={20} xs={24}>
        <h2 className="mt-20">{branchOffice.name}</h2>
          <ResponsiveContainer height={450} className="chart-container">
            <ComposedChart
              title="Comparativo"
              data={branchOffice.schedules}
            > 
            <Legend
              align="left"
              layout="horizontal" 
              className="chart-legend"
              wrapperStyle={{ 
                top: 'auto', 
                right: 20, 
                bottom: 20, 
                left: '50%', 
                transform: 'translateX(-50%)', 
                paddingLeft: '12px',
                paddingTop: '28px',
                paddingRight: '24px',
                paddingBottom: '28px', 
              }}
            />
              <CartesianGrid vertical={false} />
              <YAxis
                width={40}
                yAxisId="left"
                interval="preserveStartEnd"
                tickCount={50}
                tickLine={false}
                axisLine={true}
                domain={[0, 'dataMax + 20']}
              />
              <YAxis
                yAxisId="right"
                orientation='right'
                interval="preserveStartEnd"
                domain={[0, 'dataMax + 20']}
                tickCount={50}
                tickLine={false}
                axisLine={true}
                hide={true}
              />
              <Tooltip />
              <Bar
                yAxisId="left"
                type="monotone"
                dataKey="total_reservation"
                name="Pacientes reservados"
                fill="#223A70"
                legendType='square'
              >
                <LabelList
                  valueAccessor={(value, index) => {
                    return value.utilization_percentage
                  }}
                  content={<CustomizedLabel />}
                  formatter={labelFormatter}
                  position='top'
                />
              </Bar>
              <Bar
                yAxisId="left"
                type="monotone"
                dataKey="morning"
                name="Pacientes reservados am"
                fill="#EE8D3A"
                legendType='square'
              >
              </Bar>
              <Bar
                yAxisId="left"
                type="monotone"
                dataKey="afternoon"
                name="Pacientes reservados pm"
                fill="#7A89A9"
                legendType='square'
              >
              </Bar>
              <Line yAxisId="left"
                type="monotone"
                name="Capacidad máxima"
                dataKey="max_capacity"
                fill="#fff"
                stroke='#DC582A'
                strokeWidth={3}
                legendType='plainline'
                dot={false}
                activeDot={false}
              />
              <XAxis dataKey="day" 
              tickFormatter={formatXAxis}
              domain={['auto', 'auto']} 
              />
            </ComposedChart>
          </ResponsiveContainer>
      </Col>
    </Row>
  )
}

export default ScheduleChart;