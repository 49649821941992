import React, { useEffect, useState } from "react";
import "../index.less";
import { DatePicker, PageHeader, Select, Row, Col, Form, Button, message, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Cookies from 'universal-cookie';
import { dashboardService } from "../_services/dashboard.service";
import General from "./components/General";
import Ranking from "./components/Ranking";
import ByTypes from "./components/ByTypes";
import moment from "moment";
import BillStatus from "./components/BillStatus";
import { filter } from "lodash";

const { RangePicker } = DatePicker;
const startDate = moment().subtract(1, "months").startOf("month");
const endDate = moment().subtract(1, "months").endOf("month");
const cookies = new Cookies();

const HomePage = () => {
  const [dates, setDates] = useState([startDate, endDate]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [loading, setLoading] = useState({ branchOffices: false, general: false, byTypes: false });
  const [selectedBranchOffices, setSelectedBranchOffices] = useState();
  const [filteredBranchOffices, setFilteredBranchOffices] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const fetchBranchOffices = async () => {
    setLoading(prevLoading => ({ ...prevLoading, branchOffices: true }));
    try {
      const response = await dashboardService.getBranchOffices();
      setBranchOffices(response)
      setFilteredBranchOffices(response);
    } catch (error) {
      console.error(error)
      message.error('Ha ocurrido un error al buscar la información de sucursales');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, branchOffices: false }));
    }
  };

  const fetchGeneralData = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, general: true }));
    try {
      let response = await dashboardService.getGeneralData(params);
      response.map(obj => {
        obj.sale_amount = Number(obj.sale_amount);
      })

      response = response.sort(function (a, b) { return b.sale_amount - a.sale_amount });

      let totalAttentions = 0;
      let totalPatients = 0;
      let totalSaleAmount = 0;

      response.map(obj => {
        totalAttentions += Number(obj.attentions);
        totalPatients += Number(obj.patients);
        totalSaleAmount += Number(obj.sale_amount);
      })

      let total = {
        name: <strong>Total</strong>,
        attentions: totalAttentions,
        patients: totalPatients,
        sale_amount: totalSaleAmount
      }

      const responseWithTotal = [...response, total];
      setGeneralData([...responseWithTotal]); 
    } catch (error) {
      console.error(error);
      message.error('Ha ocurrido un error al buscar la información de prestaciones');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, general: false }));
    }
  }

  const fetchAmountByType = async (params) => {
    setLoading(prevLoading => ({ ...prevLoading, byTypes: true }));
    try {
      let response = await dashboardService.getAmountByType(params);
      response = response.map(item => {
        const total = response.map(item => {
          return parseInt(item.sum)
        }).reduce((a, b) => {
          return a + b;
        });

        return {
          name: item.payment_method,
          value: item.sum,
          percentage: Math.round(parseFloat(((100 * parseInt(item.sum)) / total)))
        }
      })

      let totalValue = 0;

      response.map(obj => {
        totalValue += Number(obj.value);
      })

      const total = {
        value: totalValue,
        name: <strong>Total</strong>
      }
      let responseWithTotal = [...response, total];
      setPaymentMethods([...responseWithTotal]);
    } catch (error) {
      console.error(error);
      message.error('Ha ocurrido un error al buscar la información de pagos');
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, byTypes: false }));
    }
  }

  const handleSubmit = (values) => {
    let params = {};

    if (values.dates) {
      params.startDate = values.dates[0].format('YYYY-MM-DD'),
      params.endDate = values.dates[1].format('YYYY-MM-DD')
      setDates(values.dates);
    } else {
      params.startDate = dates[0].format('YYYY-MM-DD')
      params.endDate = dates[1].format('YYYY-MM-DD')
    }
      
    if (values.branch_offices) {
      let branchOfficeNames = values.branch_offices
      let branchOfficeSearch = branchOffices.filter(branchOffice =>
        branchOfficeNames.includes(branchOffice.name)
      )
      let branchOfficeIds = branchOfficeSearch.map(branchOffice => branchOffice.id)
      params['selectedBranchOffices'] = branchOfficeIds;
      setSelectedBranchOffices([branchOfficeIds]);
    }
    
    fetchGeneralData(params);
    fetchAmountByType(params);
  };

  const handleSearch = (value) => {
    const filteredBranchOffices = branchOffices.filter(
      branchOffice => branchOffice.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setFilteredBranchOffices(filteredBranchOffices);
  }

  const getCountryCode = () => {
    return cookies.get('country_code');
 }

  useEffect(() => {
    fetchBranchOffices();
  }, []);

  useEffect(() => {
    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD')
    }
    fetchGeneralData(params);
    fetchAmountByType(params);
  }, [])

  return (
    <PageHeader
      className="page-header"
      title="Comercial"
      extra={[
        <Form layout="vertical" style={{ width: 550 }} onFinish={handleSubmit} className="cmt-form">
          <Row gutter={10}>
            <Col lg={12} xs={24} style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <Form.Item label="Fechas" name="dates">
                  <RangePicker
                    style={{ width: "100%" }}
                    size="large"
                    defaultValue={dates}
                    className="cmt-date-picker"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col lg={8} xs={24} style={{ width: "100%" }}>
              <Form.Item
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Sucursal
                      <Popover
                        trigger="hover"
                        overlayClassName="sidebar-tooltip"
                        placement="right"
                        content={
                          <span>
                            Selecciona y agrega las sucursales que necesites consultar
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
                      </Popover>
                  </div>
                }
                name="branch_offices"
              >
                <Select
                  className="cmt-select"
                  size="large"
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Seleccionar Sucursal"
                  onSearch={handleSearch}
                >
                  {filteredBranchOffices?.map((branchOffice) => (
                    <Select.Option value={branchOffice.name} key={branchOffice.id}>
                      {branchOffice.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} xs={24} style={{ width: "100%" }}>
              <Form.Item label=" ">
                <Button
                  size="large"
                  className="cmt-button primary"
                  type="primary"
                  htmlType="submit"
                >
                  Filtrar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>,
      ]}
    >
      <Row><h1>Fecha del {dates[0].format('DD-MM-YYYY')} al {dates[1].format('DD-MM-YYYY')}</h1></Row>
      <ByTypes paymentMethods={paymentMethods} loading={loading} />
      <General generalData={generalData} loading={loading}/>
      <Ranking dates={dates} selectedBranchOffices={selectedBranchOffices} />
      {
        getCountryCode() === 'CL' && 
        <BillStatus dates={dates} />
      }
    </PageHeader>
  );
};

export default HomePage;
