import React, { useEffect, useState, useRef } from 'react';
import { Spin, Card, Row, Col, Modal, Table } from 'antd';
import { hallwayService } from '../../_services/hallway.service';
import moment from 'moment';

const TIMEOUT = 15000;

const Admission = ({ date, selectedBranchOffice }) => {
   const columns = [
      {
         title: 'Nombres',
         dataIndex: 'names',
         key: 'names'
      },
      {
         title: 'Apellidos',
         dataIndex: 'last_name',
         key: 'last_name'
      },
      {
         title: 'Rut',
         dataIndex: 'rut',
         key: 'rut'
      },
      {
         title: 'Edad',
         dataIndex: 'years',
         key: 'years'
      }
   ];

   const intervalRef = useRef(null)

   useEffect(() => {
      fetchTotalAdmission()
      if (!intervalRef.current) {
         intervalRef.current = setInterval(async () => {
            await fetchTotalAdmission()
         }, TIMEOUT);
      }
      return () => {
         if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
         }
      };
   }, [selectedBranchOffice])

   const [visibleModal, setVisibleModal] = useState(false);
   const [loadingTotalAdmission, setLoadingTotalAdmission] = useState(false);
   const [totalCountPatientsWithAdmission, setTotalCountPatientsWithAdmission] = useState();
   const [totalCountPatientsWithExams, setTotalCountPatientsWithExams] = useState();
   const [modalDataSource, setModalDataSource] = useState([]);
   const [loadingModal, setLoadingModal] = useState(false);

   const getParams = () => {
      const params = {
         date: date.format('YYYY-MM-DD')
      }

      if (selectedBranchOffice) {
         params['selectedBranchOffice'] = selectedBranchOffice
      }

      return params;
   }

   const fetchTotalAdmission = async () => {
      setLoadingTotalAdmission(true);
      const response = await hallwayService.getTotalAdmission(getParams());
      setTotalCountPatientsWithAdmission(response.totalPatientsWithAdmission[0].count);
      setTotalCountPatientsWithExams(response.totalPatientsWithExams[0].count);
      setLoadingTotalAdmission(false);
   }

   const fetchTotalPatientsWithAdmission = async () => {
      setLoadingModal(true);
      const response = await hallwayService.getTotalPatientsWithAdmission(getParams());
      response.patientsWithAdmission.map(obj => obj.years = moment().diff(obj.birthdate, 'years'))
      setModalDataSource(response.patientsWithAdmission);
      setLoadingModal(false);
   }

   const fetchPatientsWithExams = async () => {
      setLoadingModal(true);
      const response = await hallwayService.getPatientsWithExams(getParams());
      response.patientsWithExams.map(obj => obj.years = moment().diff(obj.birthdate, 'years'))
      setModalDataSource(response.patientsWithExams);
      setLoadingModal(false);
   }

   const handleCancel = () => {
      setVisibleModal(false);
      setModalDataSource([]);
   }

   const handleOk = () => {
      setVisibleModal(false);
      setModalDataSource([]);
   }

   const showModal = async (state) => {
      setVisibleModal(true);
      if (state == 'WITH_EXAMS') {
         fetchPatientsWithExams();
      } else if (state == 'WITH_ADMISSION') {
         fetchTotalPatientsWithAdmission();
      }
   }

   return (
      <div>
         <Modal
            title="Admisión"
            visible={visibleModal}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
         >
            <Spin spinning={loadingModal}>
               <Table className="cmt-table-list" dataSource={modalDataSource} columns={columns} />
            </Spin>
         </Modal>

         <Card
            title={<strong>Admisión</strong>}>
            {
               <Spin spinning={loadingTotalAdmission}>
                  <Row>
                     {loadingTotalAdmission}
                     <Col>
                        <Card

                           onClick={() => {
                              showModal('WITH_ADMISSION');
                           }}
                           style={{ color: '#DD5E32', cursor: 'pointer', }}>
                           <Row>
                              <Col style={{ textTransform: 'uppercase', fontSize: '1.3em' }}>en espera</Col>
                           </Row>
                           <Row>
                              <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                                 {totalCountPatientsWithAdmission}
                              </Col>
                           </Row>
                           <Row>
                              <Col>pacientes</Col>
                           </Row>
                        </Card>
                     </Col>
                     <Col

                        style={{ marginLeft: 20 }}>
                        <Card
                           onClick={() => {
                              showModal('WITH_EXAMS');
                           }}
                           style={{ color: '#5FB09C', cursor: 'pointer' }}>
                           <Row>
                              <Col style={{ textTransform: 'uppercase', fontSize: '1.5em' }}>atendidos</Col>
                           </Row>
                           <Row>
                              <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                                 {totalCountPatientsWithExams}
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 pacientes
                              </Col>
                           </Row>
                        </Card>
                     </Col>
                  </Row>
               </Spin>
            }
         </Card>
      </div>
   )
}

export default Admission;