import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form, Select, DatePicker, Button } from 'antd';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { dateRanges, dateRangePlaceholder } from "./DateRanges";
import fetchCompanyNames from '../../_helpers/fetchCompanyNames';
import QualityTable from './QualityTable';
import QualityTableSummary from './QualityTableSummary';
import { formatAmount } from "../../_helpers/formatAmount";

const filters = ({ actualTab, qualityService, dashboardService, filter, setFilter }) => {
  const [branchOffices, setBranchOffices] = useState([]);
  const [prestations, setPrestations] = useState([]);
  const startDate = moment().subtract(15, 'day').format('YYYY-MM-DD'); 
  const endDate = moment().format('YYYY-MM-DD');
  const [companies, setCompanies] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);
  const [loading, setLoading] = useState({ table: false, summaryTable: false })
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const Format = ({ number, total }) => {
    if (Number.isNaN(number) || Number.isNaN(total) || total === 0) {
        return <React.Fragment>
            0%
            <span style={{ fontSize: 11 }}>0</span>
        </React.Fragment>
    }

    return <React.Fragment>
        {Math.round(((parseInt(number) / total) * 100) * 100) / 100}%
        <span style={{ fontSize: 11 }}>{number}</span>
    </React.Fragment>
  }

  const fetchTableData = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, table: true }));
    try { 
        const statistics = await qualityService.getQuality(filter, actualTab);
        let sumTotal = 0;
        for (let statistic of statistics) {
            sumTotal += parseInt(statistic.signed_less_twelve) + parseInt(statistic.signed_more_twelve) + parseInt(statistic.signed_more_twenty_four) + parseInt(statistic.signed_more_thirty_six) + parseInt(statistic.signed_more_forty_eight) + parseInt(statistic.signed_more_seventy_two);
            }
        const result = statistics.map((statistic) => {
            const total = parseInt(statistic.signed_less_twelve) + parseInt(statistic.signed_more_twelve) + parseInt(statistic.signed_more_twenty_four) + parseInt(statistic.signed_more_thirty_six) + parseInt(statistic.signed_more_forty_eight) + parseInt(statistic.signed_more_seventy_two);

            return {
                name: statistic.name,
                signed_less_twelve: <Format number={statistic.signed_less_twelve} total={total} />,
                signed_more_twelve: <Format number={statistic.signed_more_twelve} total={total} />,
                signed_more_twenty_four: <Format number={statistic.signed_more_twenty_four} total={total} />,
                signed_more_thirty_six: <Format number={statistic.signed_more_thirty_six} total={total} />,
                signed_more_forty_eight: <Format number={statistic.signed_more_forty_eight} total={total} />,
                signed_more_seventy_two: <Format number={statistic.signed_more_seventy_two} total={total} />,
                total: (
                    <React.Fragment>
                        <strong>{((parseInt(total)/sumTotal) * 100).toFixed(2)}%</strong> <span style={{ fontSize: 11 }}>{total}</span>
                    </React.Fragment>
                )
            }
        })
        setDataSource(result);
    } catch(error) {
        console.error(error)
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, table: false }));
    }
  }, [filter, actualTab]);

  const fetchSummaryTableData = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, summaryTable: true }));
    try {
      const patients = await qualityService.getPatients(filter, actualTab);
      const attentions = await qualityService.getAttentions(filter, actualTab);
      const pendingSignatures = await qualityService.getPendingSignature(
        filter,
        actualTab
      );
      const reports = await qualityService.getTotalReports(filter, actualTab);
      const lateReports = await qualityService.getLateReports(
        filter,
        actualTab
      );
      const combineCounts = patients.map((patient) => {
        const attentionsAndBranchOffice = attentions.find(
          (attention) => attention.name === patient.name
        );
        const pendingSignaturesAndBranchOffice = pendingSignatures.find(
          (pending) => pending.name === patient.name
        );
        const reportsAndBranchOffice = reports.find(
          (report) => report.name === patient.name
        );
        const lateReportsAndBranchOffice = lateReports.find(
          (report) => report.name === patient.name
        );

        return {
          name: patient.name,
          patients: patient?.patients ?? "0",
          attentions: attentionsAndBranchOffice?.attentions ?? "0",
          pendingSignature:  pendingSignaturesAndBranchOffice?.count ?? "0",
          reports: reportsAndBranchOffice?.count ?? "0",
          lateReports: lateReportsAndBranchOffice?.count ?? "0",
          pendingReports: pendingSignaturesAndBranchOffice?.prestations ?? "No hay informes pendientes",
        };
      });

      combineCounts.sort((a,b) => b.pendingSignature - a.pendingSignature);

      let totalPatients = 0;
      let totalAttentions = 0;
      let totalPendingSignatures = 0;
      let totalReports = 0;
      let totalLateReports = 0;

      let result = combineCounts.map((item) => {
        totalPatients += parseInt(item.patients);
        totalAttentions += parseInt(item.attentions);
        totalPendingSignatures += parseInt(item.pendingSignature);
        totalReports += parseInt(item.reports);
        totalLateReports += parseInt(item.lateReports);

        return {
          name: item.name,
          patients: formatAmount(item.patients),
          attentions: formatAmount(item.attentions),
          number_services_per_patient:
            item.attentions &&
            item.patients &&
            formatAmount((item.attentions / item.patients).toFixed(1)),
          pendingSignature: item.pendingSignature,
          reports: item.reports,
          lateReports: item.lateReports,
          pendingReports: item.pendingReports,
        };
      });

      if (
        totalPatients === 0 &&
        totalAttentions === 0 &&
        totalPendingSignatures === 0
      ) {
        result = [];
      } else {
        result.push({
          name: <strong>Total</strong>,
          patients: <strong>{totalPatients}</strong>,
          attentions: <strong>{totalAttentions}</strong>,
          number_services_per_patient: (
            <strong>
              {totalPatients &&
                totalAttentions &&
                formatAmount((totalAttentions / totalPatients).toFixed(1))}{" "}
            </strong>
          ),
          pendingSignature: <strong>{totalPendingSignatures}</strong>,
          reports: <span>{totalReports}</span>,
          lateReports: <strong>{totalLateReports}</strong>,
        });
      }
      setSummaryDataSource(result);
    } catch (error) {
      console.error(`Response error: ${error}`);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, summaryTable: false }));
    }
  }, [filter, actualTab]);

  const fetchPrestationsNames = debounce(async (name) => {
    const response = name ?
        await qualityService.getPrestationsNames({ name })
        : [];
    setPrestations(response.map(obj => <Select.Option value={obj.name} key={`prestation${obj.name}`}> {obj.name} </Select.Option>));
  }, 500);

  const fetchBranchOffices = useCallback(async () => {
    try {
      const response = await dashboardService.getBranchOffices();
      setBranchOffices(
        response.map((obj) => (
          <Select.Option value={obj.id} key={`branchOffice${obj.id}`}>
            {obj.name}
          </Select.Option>
        ))
      );
    } catch (error) {
      console.error(`Response error: ${error}`);
      message.error({content: 'Ha ocurrido un error al buscar la información de sucursales', style: {marginTop: '40vh'}})
    }
  }, []);

  const getCompanyNames = async (value) => {
    try {
      const response = await fetchCompanyNames(value);
      setCompanies(response?.map(obj => <Select.Option value={obj.business_name} key={obj.id}> {obj.business_name} </Select.Option>));
    } catch (error) {
      console.error(error);
      message.error({content: 'Ha ocurrido un error al buscar la información de empresas', style: {marginTop: '40vh'}})
    }
  };

  const handleChangeDates = (dates) => {
    setFilter({
      ...filter,
      startDate: dates?.[0].format('YYYY-MM-DD') ?? startDate,
      endDate: dates?.[1].format('YYYY-MM-DD') ?? endDate,
    });
  };

  const handleChangeBranchOffice = (value) => {
    setFilter({
      ...filter,
      selectedBranchOffices: value ?? '',
    });
  };

  const handleChangePrestations = (value) => {
    setFilter({
      ...filter,
      prestation: value ?? '',
    });
  };

  const handleChangeCompanies = (value, id) => {
    setFilter({
      ...filter,
      companyName: value.map(obj => obj) ?? '',
      companyId: id.map(obj => (obj.key)).join(',') ?? ''
    });
  };

  const removeFilters = () => {  
    form.resetFields();
    setFilter({
      startDate: startDate,
      endDate: endDate,
      prestation: '',
      selectedBranchOffices: '',
      companyName: '',
      companyId: ''
    });
  };

  useEffect(() => {
    fetchBranchOffices(); 
  }, [fetchBranchOffices]);

  const handleFetch = async () => {
    form.setFieldsValue({ prestations: undefined, company: undefined });
    setFilter(prevFilter => ({
      ...prevFilter,
      prestation: '',
      companyName: '',
      companyId: ''
    }));
  }

  useEffect(() => {
    handleFetch();
  }, [actualTab])

  useEffect(() => {
    if (actualTab === 'branchOffice' || actualTab === 'prestations') {
      fetchTableData();
    }     
    if (actualTab === 'byCompany' || actualTab === 'summary') {
      fetchSummaryTableData();
    }
  }, [filter])

  return (
    <>
      <Form
        form={form}
        className="cmt-form"
      >
        <Row gutter={8}>
          <Col lg={6} xs={24} className="mr-20">
            <Form.Item
              label={
                <Row className="justify-center" align="middle">
                  <Col lg={24} xs={24}>
                    <p className="text-center mb-0"> Filtrar por</p>
                  </Col>
                </Row>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="dates"
            >
              <RangePicker
                className="cmt-date-picker"
                placeholder={dateRangePlaceholder()}
                size="large"
                ranges={dateRanges()}
                onChange={handleChangeDates}
                onClear={handleChangeDates}
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24} className="mr-20">
            <Form.Item
              label={
                <Row className="justify-center" align="middle">
                  <Col lg={24} xs={24}>
                    <p className="text-center mb-0">Sucursal</p>
                  </Col>
                </Row>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="selectedBranchOffices"
            >
              <Select
                size="large"
                style={{ width: "100%", display: "block" }}
                className="cmt-select"
                placeholder="Sucursal"
                onChange={handleChangeBranchOffice}
                mode="multiple"
              >
                {branchOffices}
              </Select>
            </Form.Item>
          </Col>
          { (actualTab === "branchOffice" || actualTab === "prestations") && (
            <Col lg={6} xs={24} className="mr-20">
              <Form.Item 
                label={
                  <Row className="justify-center" align="middle">
                    <Col lg={24} xs={24}>
                      <p className="text-center mb-0">Prestaciones</p>
                    </Col>
                  </Row>
                }
                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                name="prestations"
              >
                <Select
                  size="large"
                  style={{ width: '100%', display: 'block' }}
                  className="cmt-select"
                  mode="multiple"
                  placeholder='Buscar prestaciones'
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  onSearch={fetchPrestationsNames}
                  onChange={handleChangePrestations}
                  onClear={handleChangePrestations}
                >
                  {prestations}
                </Select>
              </Form.Item>
            </Col>
          )}
          {actualTab === "byCompany" && (
            <Col lg={6} xs={24} className="mr-20">
              <Form.Item
                label={
                  <Row className="justify-center" align="middle">
                    <Col lg={24} xs={24}>
                      <p className="text-center mb-0">Empresa</p>
                    </Col>
                  </Row>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="company"
              >
                <Select
                  className="cmt-select"
                  size="large"
                  style={{ width: "100%", display: "block" }}
                  mode="multiple"
                  placeholder="Nombre o RUT"
                  onChange={handleChangeCompanies}
                  onSearch={getCompanyNames}
                >
                  {companies}
                </Select>
              </Form.Item>  
            </Col>
          )}
            <Col lg={2} xs={24} className="mr-20">
              <Button
                onClick={removeFilters}
                className="cmt-button primary non-outline mt-40"
                type="primary"
                size="small"
              >
                Borrar filtros
              </Button>
            </Col>
        </Row>
      </Form>
      {
        (actualTab === 'branchOffice' || actualTab === 'prestations')
        ? 
          <QualityTable
            actualTab={actualTab}
            qualityService={qualityService}
            dataSource={dataSource}
            loading={loading}
          />
        :
          <QualityTableSummary
            actualTab={actualTab}
            qualityService={qualityService}
            summaryDataSource={summaryDataSource}
            loading={loading}
          />
        }
    </>
  );
};
export default filters;
