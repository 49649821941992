import React from 'react';
import { Tabs, Popover } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const subHeader = ({ setActualTab }) => {

    const callback = (key) => {
      setActualTab(key)
    };

    return (
      <div>
        <Tabs className="cmt-tabs" defaultActiveKey="branchOffice" onChange={callback}>
          <TabPane 
            tab={
              <>
                Sucursal
                <Popover 
                  trigger="hover"
                  overlayClassName="sidebar-tooltip"
                  placement="right"
                  content={
                    <span>
                      Muestra asistencia y prestaciones vendidas por sucursal
                    </span>
                  }
                >  
                  <QuestionCircleOutlined className="ml-5"/>
                </Popover>
              </>
            }
            key="general" 
          >
          </TabPane>
          <TabPane 
            tab={
              <>
                Empresa
                <Popover 
                  trigger="hover"
                  overlayClassName="sidebar-tooltip"
                  placement="right"
                  content={
                    <span>
                      Muestra asistencia y prestaciones vendidas por empresa
                    </span>
                  }
                >  
                  <QuestionCircleOutlined className="question-circle" />
                </Popover>
              </>
            }
            key="resumen"
          >
          </TabPane>
        </Tabs>
      </div>
    )
}
export default subHeader;