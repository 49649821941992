import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Row, Col, Form, DatePicker, Select, Button, Card } from 'antd';
import Patients from './Patients';
import CapabilityByAmount from './CapabilityByAmount';
import ActivitySummaryTable from './ActivitySummaryTable';
import Absences from './Absences';

const { RangePicker } = DatePicker;
const cookies = new Cookies();

const General = ({ selectedBranchOffices, dates, prestationsData, patientsData, companiesData, loading,  
  reservedAmount, attendedAmount, invoicedAmount, agreementAmount }) => {
   const [selectedCompany, setSelectedCompany] = useState();

   const disabledDate = (current) => {
      return current && current > moment().endOf('day');
   }

   return (
    <div>
        <Card title="Numeros generales">
            <Absences
                dates={dates}
                selectedBranchOffices={selectedBranchOffices}
                selectedCompany={selectedCompany}
                prestationsData={prestationsData}
                patientsData={patientsData}
                companiesData={companiesData}
                loading={loading}
            />
        </Card>
        {
            cookies.get('roles').includes('gerencia') && 
                <CapabilityByAmount
                    dates={dates}
                    selectedBranchOffices={selectedBranchOffices}
                    selectedCompany={selectedCompany}
                    reservedAmount={reservedAmount}
                    attendedAmount={attendedAmount}
                    invoicedAmount={invoicedAmount}
                    agreementAmount={agreementAmount}
                    loading={loading}
                />
        }
        <Row className="mt-30">
            <Col
                lg={12}
                xs={24}
            >
            </Col>
        </Row>
        <ActivitySummaryTable
            dates={dates}
            selectedBranchOffices={selectedBranchOffices}
            prestationsData={prestationsData}
            loading={loading}
        />
        <Patients
            filterDates={dates}
            selectedBranchOffices={selectedBranchOffices}
            selectedCompany={selectedCompany}
        />
    </div>
   );
};

export default General;