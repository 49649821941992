import React, { useEffect, useState } from 'react';
import { dashboardService } from '../../_services/dashboard.service';
import { Card, Spin, Table, Row, Col, Tag } from 'antd';
import {
  PieChart, Pie, ResponsiveContainer, Cell
} from 'recharts';
import { formatAmountWithCurrency } from '../../_helpers/formatAmount';

const COLORS = ['#F29877', '#4F5970', '#BDB3A0', '#AAC1F2'];

const columns = [
  {
    title: 'Tipo',
    dataIndex: 'name',
    key: 'name',
    render: (value, row, index) => {
      return (
        <Tag color={value && COLORS[index]}>{value || 'OTROS'}</Tag>
      )
    }
  },
  {
    title: 'Monto',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
    render: (value) => {
      const formattedValue = formatAmountWithCurrency(value)
      return (
        <span style={{ float: 'right' }}>
          {formattedValue}
        </span>
      );
    }
  }
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {percent !== 0 ? `${(percent * 100).toFixed(0)}%` : ''}
    </text>
  );
};

const ByTypes = ({ paymentMethods, loading }) => {

  return (
    <Card title="Actividad por Tipo Cliente" className="mt-40">
      <Spin spinning={loading.byTypes} className="cmt-spin">
        <Row gutter={12}>
          <Col lg={12} xs={24}>
            {paymentMethods &&
              <Table
                style={{ width: '100%', overflowX: 'auto' }}
                dataSource={paymentMethods.length > 0 ? paymentMethods : []}
                columns={columns}
                pagination={false}
                className="cmt-table"
                locale={{ emptyText: 'Sin información' }}
              />
            }
          </Col>
          <Col lg={12} xs={24}>
            {paymentMethods &&
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={paymentMethods.length > 0 ? paymentMethods : []}
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={1}
                    dataKey="percentage"
                    label={renderCustomizedLabel}
                    isAnimationActive={false}
                    labelLine={false}
                  >
                    {
                      paymentMethods.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            }
          </Col>
        </Row>
      </Spin>
    </Card>

  )
}

export default ByTypes;