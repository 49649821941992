import React, { useEffect, useState, useCallback } from 'react';
import { Card, Spin, Table, Statistic, Row, Col, Popover } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { operationsService } from '../../_services/operations.service';

const PatientsCountByCompany = ({ dates, selectedBranchOffices, selectedCompany }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchPatientsCountByCompany = useCallback(async () => {
        setLoading(true);
        try {
            const response = await operationsService.getPatientsCountByCompany(getParams());
            setData(response);
        } catch (error) {
            console.error((`Response error: ${error}`))
        } finally {
            setLoading(false);
        }
    }, [dates, selectedBranchOffices, selectedCompany])

    useEffect(() => {
        fetchPatientsCountByCompany();
    }, [fetchPatientsCountByCompany])

    const getParams = () => {
        const params = {
            startDate: dates[0].format('YYYY-MM-DD'),
            endDate: dates[1].format('YYYY-MM-DD')
        }

        if (selectedBranchOffices) {
           params['selectedBranchOffices'] = selectedBranchOffices
        }

        if (selectedCompany) {
            params['selectedCompany'] = selectedCompany
        }
        return params;
    }

    const columnsPatientsCountByCompany = [
        {
            title: 'Empresa',
            dataIndex: 'name',
            key: 'name',
            width: 300,
            render: (value, index) => (
              <p 
                className= {`mb-0 ${index === data.patientsCountByCompany[data.patientsCountByCompany.length - 1] 
                && 'bold'}`}>{value}
              </p>
            ),
        },
        {
            title: 
                <div>
                    Reservados
                      <Popover 
                        trigger="hover"
                        overlayClassName="sidebar-tooltip"
                        placement="right"
                        content={
                          <span>
                            Semana móvil actual consultada versus anterior
                          </span>
                        }
                      >  
                        <QuestionCircleOutlined className="question-circle" />
                      </Popover>
                        <span className="span-small">Actual/Anterior</span>
                </div>,
            dataIndex: 'countPatientsReserved',
            key: 'countPatientsReserved',
            align: 'right',
            render: (value, record, index) => {
                const { countPatientsReserved, countPatientsReservedBefore } = record
                    return (
                      <p className={`mb-0 ${index === data.patientsCountByCompany.length - 1 && 'bold'}`}>
                        {countPatientsReserved} 
                        <span className="span-small"> /{countPatientsReservedBefore}</span>
                      </p>
                    )

            },
            width: 100,
            align: 'center'
        },
        {
            title: 
                <div>
                    Atendidos
                      <Popover 
                        trigger="hover"
                        overlayClassName="sidebar-tooltip"
                        placement="right"
                        content={
                          <span>
                            Semana móvil actual consultada versus anterior
                          </span>
                        }
                      >  
                        <QuestionCircleOutlined className="question-circle" />
                      </Popover>
                        <span className="span-small">Actual/Anterior</span>
                </div>,
            dataIndex: 'countPatientsAttended',
            key: 'countPatientsAttended',
            align: 'right',
            render: (value,record, index) => {
                const { countPatientsAttended, countPatientsAttendedBefore } = record
                return (
                  <p className={`mb-0 ${index === data.patientsCountByCompany.length - 1 && 'bold'}`}>
                    {countPatientsAttended}
                    <span className="span-small"> /{countPatientsAttendedBefore}</span>
                  </p>
                )
            },
            width: 100,
            align: 'center'
        },
        {
            title: 
            <div>
                Asistencia %
                  <Popover 
                    trigger="hover"
                    overlayClassName="sidebar-tooltip"
                    placement="right"
                    content={
                      <span>
                        Porcentaje de semana móvil actual consultada versus anterior
                      </span>
                    }
                  >  
                    <QuestionCircleOutlined className="question-circle" />
                  </Popover>
                    <span className="span-small">Actual/Anterior</span>
            </div>,
            dataIndex: 'attendancePercent',
            key: 'attendancePercent',
            align: 'right',
            render: (value, record) => {
                const { attendancePercent, attendancePercentBefore } = record
                    return (
                    <React.Fragment>
                       {attendancePercent < attendancePercentBefore && attendancePercent < 1 ? (
                       <span className ="span-red">{Math.round(attendancePercent*1000/10)}%</span>
                       ) : (
                        <span>{Math.round(attendancePercent*1000/10)}%</span>
                       )}
                    <span className="span-small"> /{Math.round(attendancePercentBefore*1000/10)}%</span>
                    </React.Fragment>
                    );
            },
            width: 100,
            align: 'center'
        }
    ];

    const [totalData, setTotalData] = useState(null);

    useEffect(() => {
        if (data && data.patientsCountByCompany && data.patientsCountByCompany.length > 0) {
        setTotalData(data.patientsCountByCompany[data.patientsCountByCompany.length - 1]);
        }
    }, [data]);

    const countPatientsReservedTotal = totalData ? totalData.countPatientsReserved : 0;
    const countPatientsReservedTotalBefore = totalData ? totalData.countPatientsReservedBefore : 0;
    const countPatientsAttendedTotal = totalData ? totalData.countPatientsAttended : 0;
    const countPatientsAttendedTotalBefore = totalData ? totalData.countPatientsAttendedBefore : 0;
    const countAttendancePercentTotal = totalData ? totalData.attendancePercent : 0;
    const countAttendancePercentTotalBefore = totalData ? totalData.attendancePercentBefore : 0;

    return (
        <Card title="Resumen Asistencia por Empresa (Top 20)">
            {
                <Spin spinning={loading} className="cmt-spin">
                    <Table
                        style={{ width: '100%', overflowX: 'auto' }}
                        dataSource={data.patientsCountByCompany}
                        columns={columnsPatientsCountByCompany}
                        className="cmt-table-list"
                        pagination={false}
                     />
                    <Row gutter={12}>
                        <Col lg={8} xs={24} >
                            <Statistic
                                className="cmt-statistic mt-50 mb-50 ml-50 mr-50"
                                title={
                                    <div>
                                        <p className="sub-title">Total reservado</p>
                                    </div>
                                }
                                value={countPatientsReservedTotal}
                                formatter={(value) => (
                                  <>
                                    <span className='bold'> {value} </span>
                                    <span className="span-medium">/{countPatientsReservedTotalBefore}</span>
                                  </>
                                )}
                              />
                        </Col>
                        <Col lg={8} xs={24} >
                            <Statistic
                                className="cmt-statistic mt-50 mb-50 ml-50 mr-50"
                                title={
                                    <div>
                                        <p className="sub-title">Total atendido</p>
                                    </div>
                                }
                                value={countPatientsAttendedTotal}
                                formatter={(value) => (
                                  <>
                                    <span className='bold'> {value} </span>
                                    <span className="span-medium">  /{countPatientsAttendedTotalBefore}</span>
                                  </>
                                )}
                            />
                        </Col>
                        <Col lg={8} xs={24} >
                            <Statistic
                                className="cmt-statistic mt-50 mb-50 ml-50 mr-50"
                                title={
                                    <div>
                                        <p className="sub-title">Porcentaje de asistencia</p>
                                    </div>
                                }
                                value={Math.round(countAttendancePercentTotal*1000/10)}
                                formatter={(value) => (
                                  <>
                                      <span className='bold'> {value}% </span>
                                    <span className="span-medium">  /{Math.round(countAttendancePercentTotalBefore*1000/10)}%</span>
                                  </>
                                )}
                            />
                        </Col>
                    </Row>
                </Spin>
            }
        </Card>
    )
}

export default PatientsCountByCompany;