import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'moment/locale/es'
import { dashboardService } from '../_services/dashboard.service';
import {
   Row, Col, Button, DatePicker, Select,
   Timeline, Form, PageHeader
} from 'antd';
import Admission from './components/Admission';
import Exams from './components/Exams';
import MedicalConsultation from './components/MedicalConsultation';
import { getCookie } from '../_services/cookies'

const HallwayPage = () => {
   moment.locale('es');
   const defaultDate = moment()
   const [branchOffices, setBranchOffices] = useState();
   const [selectedBranchOffice, setSelectedBranchOffice] = useState(
      getCookie('country_code') === 'CL' ? 1 : 21
   );
   const [loading, setLoading] = useState(false);
   const [date, setDate] = useState(defaultDate);

   const changeDate = (date) => {
      setDate(date)
   }

   const fetchBranchOffices = async () => {
      setLoading(true);
      const response = await dashboardService.getBranchOffices(getParams());
      setBranchOffices(response.map(obj => <Select.Option value={obj.id} key={obj.id}>{obj.name}</Select.Option>));
      setLoading(false);
   }

   const handleBranchOffices = (selected) => {
      setSelectedBranchOffice(selected);
   }

   const getParams = () => {
      const params = {
         date: date.format('YYYY-MM-DD')
      }
      if (selectedBranchOffice) {
         params['selectedBranchOffice'] = selectedBranchOffice
      }
      return params;
   }

   useEffect(() => {
      fetchBranchOffices();
   }, []);

   return (
      <PageHeader
         style={{ 'backgroundColor': 'white' }}
         title="Pasillo"
         extra={[
            <Form layout="vertical">
               <Row gutter={8}>
                  <Col lg={24} xs={24}>
                     <Form.Item label="Sucursal">
                        <Select
                           onChange={handleBranchOffices}
                           placeholder='Seleccionar Sucursal'
                           defaultValue={selectedBranchOffice}
                           className="cmt-select"
                        >
                           {branchOffices}
                        </Select>
                     </Form.Item>
                  </Col>

                  <Col style={{ display: 'none' }}>
                     <Form.Item label='Fecha'>
                        <DatePicker size="large" onChange={changeDate} defaultValue={date} />
                     </Form.Item>
                  </Col>
                  <Col style={{ display: 'none' }}>
                     <Form.Item label=' '>
                        <Button className="cmt-button primary" size="large" type="primary" style={{ marginLeft: 5 }} >Actualizar</Button>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>,
            <div>
               {defaultDate.format('dddd[,]  DD [de] MMMM [de] YYYY')}
            </div>
         ]}
      >
         <Timeline style={{ marginTop: 30 }}>

            <Timeline.Item color='black'>
               <Admission
                  selectedBranchOffice={selectedBranchOffice}
                  date={date}
               ></Admission>
            </Timeline.Item>

            <Timeline.Item color='black' >
               <Exams
                  selectedBranchOffice={selectedBranchOffice}
                  date={date}
               ></Exams>
            </Timeline.Item>

            <Timeline.Item color='black'>
               <MedicalConsultation selectedBranchOffice={selectedBranchOffice}
                  date={date}>
               </MedicalConsultation>
            </Timeline.Item>
         </Timeline>
      </PageHeader>
   )
}

export default HallwayPage;