import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (name) => {
  return cookies.get(name);
}

export const getAllCookies = () => {
  return cookies.getAll();
}

export const setCookie = (name, value, options) => {
  cookies.set(name, value, {
    ...(options ? options : { expires: new Date('2099-01-01') }),
    path: "/",
    ...(process.env.NODE_ENV !== 'development' && {domain: "cmtx.in"})
  });
}

export const removeCookie = (name, options) => {
  cookies.remove(name, {
    ...(options ? options : { expires: new Date('2099-01-01') }),
    path: "/",
    ...(process.env.NODE_ENV !== 'development' && {domain: "cmtx.in"})
  });
}

export const listenerCookie = (callback) => {
  cookies.addChangeListener((data) => {
    callback(data);
  })
}