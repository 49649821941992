import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { dashboardService } from '../_services/dashboard.service';
import { scheduleService } from '../_services/schedule.service';
import { Spin, Card, PageHeader, Divider, DatePicker, Form, Row, Col, Button, Select, message } from 'antd';
import ScheduleChart from './components/ScheduleChart';
import ScheduleTable from './components/ScheduleTable';
import { handlers } from '../_helpers/helpers';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

const startDate = moment()

const SchedulePage = () => {
    const [loading, setLoading] = useState(false);
    const [branchOffices, setBranchOffices] = useState([]);
    const [selectedBranchOffices, setSelectedBranchOffices] = useState([]);
    const [selectedDate, setSelectedDate] = useState([startDate]);
    const [total, setTotal] = useState({
        id: 0,
        name: 'Total',
        schedules: []
    });
    
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = {
        ...Object.fromEntries(urlSearchParams.entries()),
    };
    const [filters, setFilters] = useState(Object.keys(queryParams).length !== 0 ? queryParams : {});
   
    useEffect(() => {
        if (branchOffices.length > 0) { 
        handleUrlChange(queryParams)
        } 
    }, [window.location.search, branchOffices])
  

    useEffect(() => {
        fetchBranchOffices();
    }, [])

    const updateFiltersAndFetchSchedules = (data) => {
        setFilters(data);
        fetchSchedules(data);
    };

    const handleSubmit = (values) => {
        let filteredBranchOffices = branchOffices;
        if (values.branch_offices && values.branch_offices.length > 0) {
            filteredBranchOffices = branchOffices.filter(branchOffice => values.branch_offices.includes(branchOffice.id));
        }

        const checkSelectedDate = values.date && values.date > 0 ? (values.date).format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD')  
        setSelectedDate([checkSelectedDate]);

        const queryData = {
            ...(checkSelectedDate && { date: checkSelectedDate })
        };

        if (values.branch_offices && values.branch_offices.length > 0) {
            queryData.filteredBranchOffices = filteredBranchOffices.map(branchOffice => branchOffice.id).join(',');
        }

        handlers.replaceUrlQueryString(history, queryData);
    }

    const handleUrlChange = async (queryParams) => {
      let filteredBranchOffices = branchOffices;
      let filteredBranchOfficeIds
      if (queryParams?.filteredBranchOffices?.length > 0) {
        filteredBranchOfficeIds = (queryParams.filteredBranchOffices).split(',').map(id => parseInt(id))
        filteredBranchOffices = branchOffices.filter(branchOffice => filteredBranchOfficeIds.includes(branchOffice.id));
      }
      let checkSelectedDate
      if (queryParams?.date?.length > 0) {
        checkSelectedDate = queryParams.date
      } else checkSelectedDate = startDate.format('YYYY-MM-DD')
      setSelectedDate([checkSelectedDate]);
      
      const data = {
        filteredBranchOffices,
        ...(checkSelectedDate && { date: checkSelectedDate })
      }
      updateFiltersAndFetchSchedules(data);
    };

    const fetchBranchOffices = async () => {
      try {
        const response = await dashboardService.getBranchOffices();
        setBranchOffices(response);
      } catch (error) {
        console.error(error)
        message.error('Ha ocurrido un error al buscar la información de empresas');
      }
    };

    const fetchSchedules = async ({filteredBranchOffices, date}) => {
      setLoading(true);
      setSelectedBranchOffices([]);
      let _total = {};

      for (let [index, item] of filteredBranchOffices.entries()) { 
        try {
          const schedules = await scheduleService.getSchedule({branchOfficeId: item.id, startDate: date});
          item.schedules = schedules;
          if (index === 0) {
            _total = {
                id: -1,
                name: 'Total',
                schedules: cloneDeep(schedules)
            }
          };

          if (index > 0) {
            for (let schedule of schedules) {
              const scheduleIndex = _total.schedules.findIndex(totalItem => totalItem.day === schedule.day);
              if (scheduleIndex > -1) {
                const detail = _total.schedules[scheduleIndex];
                detail.total_reservation = parseInt(detail.total_reservation) + parseInt(schedule.total_reservation);
                detail.morning = parseInt(detail.morning || 0) + parseInt(schedule.morning || 0);
                detail.max_capacity = parseInt(detail.max_capacity) + parseInt(schedule.max_capacity);
                detail.afternoon = parseInt(detail.afternoon || 0) + parseInt(schedule.afternoon || 0);
                let result = (detail.total_reservation || 0) / detail.max_capacity * 100;
                detail.utilization_percentage = isNaN(result) ? 0 : parseFloat(result.toFixed(2));
              }
            }
          };
        } catch(error) {
          console.error(error)
          message.error('Ha ocurrido un error al buscar la información de agenda');
        }
        setTotal(_total);
        setSelectedBranchOffices(prevArray => [...prevArray, item]);
      }
      setLoading(false);
    };

    const disabledDate = (selectedDate) => {
      return selectedDate && selectedDate > moment().endOf('day');
    }

    return (
      <PageHeader
        className="schedule-page"
        style={{ 'backgroundColor': 'white', paddingTop: '36px' }}
        title="Agenda"
      >
        <ScheduleTable
          scheduleService={scheduleService}
        />
        <Form className="cmt-form" size="large" layout='vertical' onFinish={handleSubmit}>
          <Card title="Capacidad de agenda semanal" className="mt-30">
            <Row gutter={10}>
              <Col lg={8} xs={24}>
                <Form.Item label="Fecha" name="date">
                  <DatePicker
                    className="cmt-date-picker"
                    style={{ width: '100%' }}
                    size="large"
                    value={selectedDate} 
                    placeholder='Desde'
                    disabledDate= {disabledDate}/>
                </Form.Item>
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item label='Sucursal' name="branch_offices">
                  <Select className="cmt-select" size="large"
                      mode='multiple'
                      placeholder='Seleccionar Sucursal'
                  >
                    {branchOffices?.map(branchOffice => (
                      <Select.Option key={branchOffice.id} value={branchOffice.id}>
                      {branchOffice.name}
                      </Select.Option>
                  ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={4} xs={24}>
                <Button size="large"
                  className="cmt-button primary mt-40"
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                      setFilters({});
                  }}
                >
                Filtrar
                </Button>
              </Col>
            </Row>
              <Spin spinning={loading} className="cmt-spin">
                <Row justify="center"> 
                  <Col lg={12} xs={24}> 
                      <ScheduleChart key={total.id} branchOffice={total}/>
                  </Col>
                </Row>
                <Row>
                {selectedBranchOffices?.map((branchOffice) => (
                  <Col lg={12} xs={24} key={branchOffice.id}>
                    <ScheduleChart
                        branchOffice={branchOffice}
                        dates={selectedDate}
                    />
                  </Col>
                ))}
                </Row>
              </Spin>
            <Divider />
          </Card>
        </Form>
    </PageHeader>
    )
}
export default SchedulePage;