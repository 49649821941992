import React from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import { Layout, Menu } from 'antd';
import Cookies from 'universal-cookie';
import config from '../config';

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
const cookies = new Cookies();

const PrivateLayout = ({ component: Component, ...rest }) => {
   const location = useLocation();

   const getRoles = () => {
      return cookies.get('roles');
   }

   const logout = () => {
      cookies.remove('user');
      cookies.remove('roles');
      window.location.href = config.loginRedirect;
   }

   return (
      <Layout style={{ backgroundColor: 'white'  }}>
         <Header className="cmt-header">
            <div className="logo" />
            <Menu
               theme="light"
               mode="horizontal"
               selectedKeys={[location.pathname]}
               defaultSelectedKeys={['1']}
               triggerSubMenuAction="click"
            >
               {
                  getRoles().includes('gerencia') &&
                     <Menu.Item key="/">
                        <Link to="/">Comercial</Link>
                     </Menu.Item>
               }

               <Menu.Item key="/operaciones">
                  <Link to='/operaciones'>Operaciones</Link>
               </Menu.Item>
               <Menu.Item key="/personal-medico">
                  <Link to='/personal-medico'>Personal Médico</Link>
               </Menu.Item>
               <Menu.Item key="/pasillo">
                  <Link to='/pasillo'>Pasillo</Link>
               </Menu.Item>
               <Menu.Item key="/agenda">
                  <Link to='/agenda'>Agenda</Link>
               </Menu.Item>
               <Menu.Item key="/calidad">
                  <Link to='/calidad'>Calidad</Link>
               </Menu.Item>
               <Menu.Item key="/whatsapp">
                  <Link to='/whatsapp'>Whatsapp</Link>
               </Menu.Item>
               <SubMenu style={{ float: 'right' }} key='SubMenu' icon={<i className="icon-system-user" />}>
                  <Menu.ItemGroup title=''>
                     <Menu.Item key="setting:1" onClick={logout}>Cerrar sesión</Menu.Item>
                     {/* <Menu.Item key="setting:2">Option 2</Menu.Item> */}
                  </Menu.ItemGroup>
               </SubMenu>
            </Menu>
         </Header>
         <Content style={{ padding: '0 50px', marginTop: 55 }}>
            <Layout>
               <Content
                  style={{
                  minHeight: '100vh',
                  }}
               >
                  <Component {...rest} />
               </Content>
            </Layout>
         </Content>
      </Layout>
   )
}

export default withRouter(PrivateLayout);