import React, { useEffect, useState } from 'react';
import { Spin, Card, Table } from 'antd';
import { operationsService } from '../../_services/operations.service';

const CompaniesWithMostServices = ({ dates, selectedBranchOffices, selectedCompany }) => {
	const [loading, setLoading] = useState(false)

	const columnsCompaniesWithMostServices = [
		{
			title: 'Empresa',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Prestaciones Realizadas',
			dataIndex: 'count',
			key: 'count',
			align: 'right',
			render: (value) => {
				return <span style={{ float: 'right' }}>{value}</span>
			}
		},
	];

	useEffect(() => {
		fetchCompaniesWithMostServices();
	}, [dates, selectedBranchOffices, selectedCompany]);

	const [data, setData] = useState([]);

	const fetchCompaniesWithMostServices = async () => {
		try {
			setLoading(true)
			const response = await operationsService.getCompaniesWithMostServices(getParams());
			setData(response);
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const getParams = () => {
		const params = {
			startDate: dates[0].format('YYYY-MM-DD'),
			endDate: dates[1].format('YYYY-MM-DD')
		}

		if (selectedBranchOffices) {
			params['selectedBranchOffices'] = selectedBranchOffices
		}

		if (selectedCompany) {
			params['selectedCompany'] = selectedCompany
		}

		return params;
	}

	return (
		<Card title="Empresas con más prestaciones">
			{
				<Spin spinning={loading} className="cmt-spin">
					<Table
						style={{ width: '100%', overflowX: 'auto' }}
						dataSource={data}
						columns={columnsCompaniesWithMostServices}
						className="cmt-table-list"
					/>
				</Spin>
			}
		</Card>
	)
}

export default CompaniesWithMostServices;