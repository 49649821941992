import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Button, Tabs, Form, Col, Row, DatePicker } from "antd";
import CallLog from "./components/CallLog";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const startDate = moment().subtract(1, "week").startOf("week");
const endDate = moment().subtract(1, "week").endOf("week");

const callback = (key) => {
  console.info(key);
};

const CallCenterPage = () => {
  const [dates, setDates] = useState([startDate, endDate]);

  const handleSubmit = (values) => {
    setDates(values["dates"]);
  };

  return (
    <Card
      extra={[
        <Form layout="vertical" style={{ width: 450 }} onFinish={handleSubmit}>
          <Row gutter={10}>
            <Col lg={18} xs={24}>
              <Form.Item label="Fechas" name="dates">
                <RangePicker
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue={dates}
                  className="cmt-date-picker"
                />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24} style={{ width: "100%" }}>
              <Form.Item label=" ">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className="cmt-button primary"
                >
                  Filtrar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>,
      ]}
    >
      <Tabs className="cmt-tabs" defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Registro de llamadas" key="1">
          <CallLog dates={dates} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default CallCenterPage;
