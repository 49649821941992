import React, { useEffect, useState } from "react";
import { Card, Spin, Table } from "antd";
import { operationsService } from "../../_services/operations.service";

const CompaniesWithMoreAbsences = ({
  dates,
  selectedBranchOffices,
  selectedCompany,
}) => {
  const [loading, setLoading] = useState(false);

  const columnsCompaniesWithMoreAbsences = [
    {
      title: "Empresa",
      dataIndex: "business_name",
      key: "business_name",
    },
    {
      title: "Prestaciones perdidas",
      dataIndex: "absences",
      key: "absences",
      align: "right",
      render: (value) => {
        return (
          <span
            style={{ float: "right" }}
          >{value < 0 ? 0 : value}</span>
        );
      },
    },
    {
      title: "Inasistencia",
      dataIndex: "absences_percentage",
      key: "absences_percentage",
      align: "right",
      render: (value, row, index) => {
        return (
          <span
            style={{ float: "right" }}
          >{row.absences_percentage < 0 ? 0 : row.absences_percentage}%</span>
        );
      },
    },
  ];

  useEffect(() => {
    fetchCompaniesWithMoreAbsencesRanking();
  }, [dates, selectedBranchOffices, selectedCompany]);

  const [
    companiesWithMoreAbsencesRanking,
    setCompaniesWithMoreAbsencesRanking,
  ] = useState();

  const fetchCompaniesWithMoreAbsencesRanking = async () => {
    setLoading(true);
    try {
      const response =
        await operationsService.getCompaniesWithMoreAbsencesRanking(getParams());
      setCompaniesWithMoreAbsencesRanking(response);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  };

  const getParams = () => {
    const params = {
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
    };

    if (selectedBranchOffices) {
      params["selectedBranchOffices"] = selectedBranchOffices;
    }

    if (selectedCompany) {
      params["selectedCompany"] = selectedCompany;
    }
    return params;
  };

  return (
    <Card title="Empresas con mayor inasistencias">
      {
        <Spin spinning={loading} className="cmt-spin">
          <Table
            style={{ width: "100%", overflowX: "auto" }}
            dataSource={companiesWithMoreAbsencesRanking}
            columns={columnsCompaniesWithMoreAbsences}
            className="cmt-table-list"
          />
        </Spin>
      }
    </Card>
  );
};

export default CompaniesWithMoreAbsences;
