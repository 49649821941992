import React, { useEffect, useState } from 'react';
import { Table, Spin, Card, Popover } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { dashboardService } from '../../_services/dashboard.service';
import { formatAmount, formatAmountWithCurrency } from '../../_helpers/formatAmount';

const General = ({ generalData, loading }) => {

  const columns = [
    {
      title: 'Sucursal',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <strong> Pacientes
          <Popover
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Número total de pacientes admisionados por sucursal
              </span>
            }
          >
            <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
          </Popover>
        </strong>
      ),
      dataIndex: 'patients',
      key: 'patients',
      align: 'right',
      render: (value) => {
        const formattedValue = formatAmount(value)
        return (
          <span style={{ float: 'right' }}>
            {formattedValue}
          </span>
        );
      }
    },
    {
      title: (
        <strong> Prestaciones
          <Popover
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Número total de prestaciones ejecutadas por sucursal
              </span>
            }
          >
            <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
          </Popover>
        </strong>
      ),
      dataIndex: 'attentions',
      key: 'attentions',
      align: 'right',
      render: (value) => {
        const formattedValue = formatAmount(value)
        return (
          <span style={{ float: 'right' }}>
            {formattedValue}
          </span>
        );
      }
    },
    {
      title: (
        <strong> Actividad
          <Popover
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Consumo total ingresado por sucursal
              </span>
            }
          >
            <QuestionCircleOutlined className="question-circle" style={{ marginBottom: "2px" }} />
          </Popover>
        </strong>
      ),
      dataIndex: 'sale_amount',
      key: 'sale_amount',
      align: 'right',
      render: (value) => {
        const formattedValue = formatAmountWithCurrency(value)
        return (
          <span style={{ float: 'right' }}>
            {formattedValue}
          </span>
        );
      }
    },
  ];  

  return (
    <Card title="Números Generales" style={{ marginTop: 40 }}>
      <Spin spinning={loading.general} className="cmt-spin">
        <Table
          style={{ width: '100%', overflowX: 'auto' }}
          dataSource={generalData.length > 0 ? generalData : []}
          columns={columns}
          pagination={false}
          className="cmt-table-list"
        />
      </Spin>
    </Card>
  )
}

export default General;