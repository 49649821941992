import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { Spin, Row, Col, Card, message, Popover } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';
import { formatAmount } from '../../_helpers/formatAmount';

const RankingPieCharts = ({ id, dates, limit, rankingType, isPieOpen, selectedBranchOffices }) => {
  const [rankingPieCharts, setRankingPieCharts] = useState();
	const [loading, setLoading] = useState(false);
  const COLORS = ['#F29877', '#4F5970', '#BDB3A0', '#AAC1F2', '#F7DF95', '#06789D'];
  const [activeLegend, setActiveLegend] = useState({ active: null, payload: null });

	const fetchRankingPieCharts = async () => {
		setLoading(true);
    const params = {
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      limit: limit,
      type: rankingType,
			id: id
    }

    if (selectedBranchOffices) {
      params.selectedBranchOffices = selectedBranchOffices.join();
    }

		try {
    	const response = await dashboardService.getRankingPieCharts(params);
    	setRankingPieCharts(response);
  	} catch (error) {    
    	console.error(error);
			message.error('Ha ocurrido un error al buscar la información');
  	} finally {
 			setLoading(false);
		}

	}

	useEffect(() => {
		if (isPieOpen) {
			fetchRankingPieCharts();
		}
	}, [id, selectedBranchOffices, dates]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontSize={10} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${percent.toFixed(0)}%`}
      </text>
    );
  };

  const renderTooltip = (entry) =>
    (
      <div className="others-tooltip">
        {
          entry.details.map((item, index) => {
            return (
              <span className="tooltip-text" key={index}>
                {formatAmount((Object.values(item)[0]).toFixed(1))}% {Object.keys(item)[0]}{index < entry.details.length - 1 ? ', ' : ''}
              </span>
            ) 
          })
        }
      </div>
    )

  const othersTooltip = ({ payload }) => {
    const entry = payload[0]?.payload;
    if (entry?.name === "Otros") { 
      return renderTooltip(entry);
    }
    return null;
  };

  const legendTooltip = (entry) => {
    if (activeLegend.payload) {
      entry = activeLegend.payload
    }
    return renderTooltip(entry);
  };

  const defineActiveLegend = (data, type) => {
    let hoveredEntry;
    if (type === 'prestation') {
      hoveredEntry = rankingPieCharts[0]?.topFourPrestations.find(entry => entry.name === data.value);
    } else {
      hoveredEntry = rankingPieCharts[0]?.topFourBranchOffices.find(entry => entry.name === data.value);
    }
    setActiveLegend({ active: data.value, payload: hoveredEntry });
  }

  const handlePrestationHover = (data) => {
    defineActiveLegend(data, 'prestation')
  };

  const handleBranchOfficeHover = (data) => {
    defineActiveLegend(data, 'branchOffice')
  };

  const handleLegendLeave = () => {
    setActiveLegend({ active: null, payload: null });
  };

return (
	<Spin spinning={loading} className="cmt-spin">
	{rankingPieCharts?.length > 0 && (	
		<Row justify="space-around">
			<Col span={9}>
				<Card>
					<Row>
						<Col lg={24} xs={24}>
							<p><strong>Prestaciones Principales</strong></p>
						</Col>
						<Col>
							<ResponsiveContainer width={360} height={320}>
								<PieChart>
									<Pie
										data={rankingPieCharts[0].topFourPrestations}
										cx={150}
										cy={100}
										labelLine={false}
										label={renderCustomizedLabel}
										isAnimationActive={false}
										innerRadius={40}
										outerRadius={80}
										fill="#8884d8"
										paddingAngle={1}
										dataKey="percent"
									>
										{rankingPieCharts[0]?.topFourPrestations.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
									</Pie>
                  <Tooltip 
                    position={{ x: 180, y: 50 }}
                    content={othersTooltip}
                  />
                  <Legend
                    wrapperStyle={{ fontSize: "12px" }}
                    layout="vertical"
                    align="middle"
                    iconSize={10}
                    payload={rankingPieCharts[0]?.topFourPrestations.map((entry) => ({ 
                      value: activeLegend.active === "Otros" ? 
                        <Popover
                          content={legendTooltip(entry)} 
                        >
                          {entry.name}
                        </Popover>
                        :
                        entry.name,
                      color: COLORS[rankingPieCharts[0].topFourPrestations.indexOf(entry) % COLORS.length] 
                    }))}
                    onMouseEnter={handlePrestationHover}
                    onMouseLeave={handleLegendLeave}
                  />
								</PieChart>
              </ResponsiveContainer>
						</Col>
					</Row>
				</Card>
			</Col>
			<Col span={9}>
				<Card>
					<Row>
						<Col lg={24} xs={24}>
							<p><strong>Sucursales Principales</strong></p>
						</Col>
						<Col>
							<ResponsiveContainer width={400} height={320}>
								<PieChart>
									<Pie
										data={rankingPieCharts[0].topFourBranchOffices}                            
										cx={150}
										cy={100}
										labelLine={false}
										label={renderCustomizedLabel}
										isAnimationActive={false}
										innerRadius={40}
										outerRadius={80}
										fill="#8884d8"
										paddingAngle={1}
										dataKey="percent"
									>
										{rankingPieCharts[0]?.topFourBranchOffices?.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))}
									</Pie>
                  <Tooltip 
                    position={{ x: 180, y: 50 }}
                    content={othersTooltip}
                  />
                  <Legend
                    wrapperStyle={{ fontSize: "12px" }}
                    layout="vertical"
                    align="middle"
                    iconSize={10}
                    payload={rankingPieCharts[0]?.topFourBranchOffices.map((entry) => ({ 
                      value: activeLegend.active === "Otros" ? 
                        <Popover
                          content={legendTooltip(entry)} 
                        >
                          {entry.name}
                        </Popover>
                        :
                        entry.name,
                      color: COLORS[rankingPieCharts[0].topFourBranchOffices.indexOf(entry) % COLORS.length] 
                    }))}
                    onMouseEnter={handleBranchOfficeHover}
                    onMouseLeave={handleLegendLeave}
                  />  
                </PieChart>
							</ResponsiveContainer>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	)}
	</Spin>
)

}

export default RankingPieCharts;