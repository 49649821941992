import { getCookie } from '../_services/cookies'

export function formatAmount(amount) {
	return new Number(amount).toLocaleString('es-Cl')
}

export function formatAmountWithCurrency(amount) {
	let value
	if (getCookie('country_code') === 'CL') {
		value = '$' + Math.trunc(new Number(amount)).toLocaleString('es-Cl')
	}
	else {
		value = 'S/ ' + new Number(amount).toLocaleString('es-Cl')
	}
	return value
}
