import React, { useEffect, useState } from 'react';
import { Spin, Card, Table } from 'antd';
import { formatAmount } from '../../_helpers/formatAmount';

const Attendance = ({  prestationsData, patientsData, companiesData, loading }) => {

    const attendanceColumns = [
        {
            title: 'Tipo',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Reservados',
            dataIndex: 'reserved',
            key: 'reserved',
            align: 'right',
            render: (value) => {
                return <span style={{ float: 'right' }}>
                    {formatAmount(value)}
                </span>

            }
        },
        {
            title: 'Atendidos',
            dataIndex: 'attended',
            key: 'attended',
            align: 'right',
            render: (value) => {
                return <span style={{ float: 'right' }}>
                    {formatAmount(value)}
                </span>
            }
        },
        {
            title: 'Asistencia',
            dataIndex: 'attendance',
            key: 'attendance',
            align: 'right',
            render: (value) => {
                return <span style={{ float: 'right' }}>
                    {formatAmount(parseInt(value))}%
                </span>
            }
        }
    ]

    const [batteriesAndExams, setBatteriesAndExams] = useState([
        {
            key: "Batería",
            reserved: 0,
            attended: 0,
            attendance: 0
        },
        {
            key: "Exámen suelto",
            reserved: 0,
            attended: 0,
            attendance: 0
        }
    ]);

    const [patients, setPatients] = useState([
        {
            key: "Paciente",
            reserved: 0,
            attended: 0,
            attendance: 0
        }
    ]);

    const [companies, setCompanies] = useState([
        {
            key: "Empresa",
            reserved: 0,
            attended: 0,
            attendance: 0
        }
    ]);

    const loadAllData = async () => {
      await Promise.all([
        getBatteriesAndExamsCount(), 
        getPatientsCount(), 
        getCompaniesCount()
      ]);
    }
    
  const calculateSum = (response, key) => {
    if (!response || !response[key]) {
      return 0;
    }
    return response[key].reduce((sum, item) => sum + parseInt(item.count, 10), 0);
  };

   const getBatteriesAndExamsCount = async () => {
        const newState = [...batteriesAndExams];
        newState[0].reserved = calculateSum(prestationsData, 'countBatteriesReserved');
        newState[0].attended = calculateSum(prestationsData, 'countBatteriesAttended');
        newState[0].attendance = (newState[0].reserved !== 0 && newState[0].attended !== 0) ? 
          `${(newState[0].attended / newState[0].reserved) * 100} %` : '0';
        newState[1].reserved = calculateSum(prestationsData, 'countExamsReserved');
        newState[1].attended = calculateSum(prestationsData, 'countExamsAttended');
        newState[1].attendance = (newState[1].reserved !== 0 && newState[1].attended !== 0) ?
          `${(newState[1].attended / newState[1].reserved) * 100}%` : '0%';
        setBatteriesAndExams(newState);
    } 

    const getPatientsCount = async () => {
        const newState = [...patients];
        newState[0].reserved = patientsData?.countReservedPatients[0]?.count ?? 0;
        newState[0].attended = patientsData?.countAttendedPatients[0]?.count ?? 0;
        newState[0].attendance = (newState[0].reserved != 0 && newState[0].attended != 0) ? 
            `${(newState[0].attended / newState[0].reserved) * 100}%` : '0%';
        setPatients(newState);        
    }

    const getCompaniesCount = async () => {
        const newState = [...companies];
        newState[0].reserved = companiesData?.countReservedCompanies[0]?.count ?? 0;
        newState[0].attended = companiesData?.countAttendedCompanies[0]?.count ?? 0;
        newState[0].attendance = (newState[0].reserved != 0 && newState[0].attended != 0) ? 
            `${(newState[0].attended / newState[0].reserved) * 100}%` : '0%';
        setCompanies(newState);
    }

    const combinedData = [...batteriesAndExams, ...patients, ...companies];

    useEffect(() => {
      if (prestationsData && patientsData.countAttendedPatients && companiesData.countAttendedCompanies) { 
        loadAllData();
      }
    }, [prestationsData]);


    return (
        <Card>
            {
                <Spin spinning={loading.batteriesAndExams || loading.patients || loading.companies} 
                  className="cmt-spin"
                >
                  <Table 
                     style={{ width: '100%', overflowX: 'auto' }} 
                     pagination={false}
                     dataSource={combinedData} 
                     columns={attendanceColumns}
                     className="cmt-table-list" 
                  />
                </Spin>
            }
        </Card>
    )
}

export default Attendance;