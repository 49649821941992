import { useState, useEffect, Fragment } from 'react';
import { Row, Col, Statistic, Spin } from 'antd';
import { dashboardService } from '../../_services/dashboard.service';
import moment from 'moment';

const BillStatus = () => {
  const [loading, setLoading] = useState(false);
  const [unpaid, setUnpaid] = useState();
  const [pendingToBill, setPendingToBill] = useState();
  const [currentMonth, setCurrentMonth] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');

  const fetchBillsByUnpaid = async () => {
    setLoading(true);
    const response = await dashboardService.getBillsByUnpaid();
    setUnpaid(response[0]?.sum_unpaid_invoices);
    setLoading(false);
  };

  const fetchPendingToBill = async () => {
    setLoading(true);
    const response = await dashboardService.getPendingToBill();
    setPendingToBill(response.total);
    setLoading(false);
  };

  const fetchCurrentMonth = async () => {
    setLoading(true);
    const response = await dashboardService.getCurrentMonth();
    const definedResponse = !response[0] ? 0 : response[0]
    setCurrentMonth(definedResponse);
    setLoading(false);
  };

  useEffect(() => {
    const params = { page: currentPage };
    fetchBillsByUnpaid();
    fetchPendingToBill();
    fetchCurrentMonth();
  }, []);

  const formatAmount = (amount) => {
    return new Number(amount).toLocaleString('es-Cl');
  }

  const sumPendingCredit = []
  currentMonth && pendingToBill && unpaid &&
    sumPendingCredit.push(formatAmount(parseInt(unpaid) + parseInt(pendingToBill) + parseInt(currentMonth.sum)))

  return (
    <Row justify="center" className="mt-60 mb-30" >
      <Col lg={24}>
        <Row justify="center">
          <Col lg={24}>
            <h2>Estado de cuentas de convenio acumulado a la fecha</h2>
          </Col>
          <Col lg={24}>
            {
              loading ?
                <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                :
                <Fragment>
                  <Row gutter={15}>
                    <Col lg={6}>
                      <Statistic
                        className="cmt-statistic"
                        title={
                          <div>
                            <p className="sub-title">Facturas por pagar</p>
                          </div>
                        }
                        value={unpaid && formatAmount(unpaid)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Statistic
                        className="cmt-statistic"
                        title={
                          <div>
                            <p className="sub-title">Pendiente de facturación</p>
                          </div>
                        }
                        value={pendingToBill && formatAmount(pendingToBill)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Statistic
                        className="cmt-statistic"
                        title={
                          <div>
                            <p className="sub-title">Actividad mes en curso</p>
                          </div>
                        }
                        value={currentMonth && formatAmount(currentMonth.sum)}
                      />

                    </Col>
                    <Col lg={6}>
                      <Statistic
                        className="cmt-statistic"
                        title={
                          <div>
                            <p className="sub-title">Total en crédito</p>
                          </div>
                        }
                        value={sumPendingCredit}
                      />
                    </Col>
                  </Row>
                </Fragment>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BillStatus;