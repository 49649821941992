import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies';

export const scheduleService = {
  getSchedule,
  getScheduleTable
};

function getSchedule(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/schedule/getSchedule/`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getScheduleTable(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/schedule/getScheduleTable`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}
