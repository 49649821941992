import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies';

export const medicalStaffService = {
  getHourlyAttendance,
  getStaff,
  getStaffRanking,
};

function getHourlyAttendance(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/medicalStaff/hourlyAttendance`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getStaff(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/medicalStaff/staff`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

function getStaffRanking(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      country_code: getCookie('country_code')
    }
  };

  const url = new URL(`${config.apiUrl}/dashboard/medicalStaff/staffRanking`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}