import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import config from '../config';
import PrivateLayout from './PrivateLayout';

function PrivateRoute({ component: Component, roles, ...rest }) {
   const cookies = new Cookies();

   return (
      <Route {...rest} render={props => {
         if (!cookies.get('user') || !cookies.get('roles')) {
            window.location.href = config.loginRedirect;
            return;
         }

         if (cookies.get('roles') && !cookies.get('roles').includes('gerencia') && props.location.pathname != '/operaciones' && props.location.pathname == '/') {
            return <Redirect to="/operaciones" />
         } else {
            return <PrivateLayout component={Component} {...rest} />
         }

            // logged in so return component
      }} />
   );
}

export default PrivateRoute;