import React from "react";
import moment from 'moment';
const { TabPane } = Tabs;
import { PageHeader, Tabs, Row, Col } from "antd";

const subHeader = ({ setActualTab, filter }) => {
  const callback = (key) => {
    setActualTab(key);
  };

  return (
    <PageHeader title="Calidad">
      <Row className="mb-20">
        <Col lg={16} xs={12}>
          <h3>
            Disponibilidad de Informes. Plazos reflejados excluyen feriados y
            domingos.
          </h3>
        </Col>
        <Col lg={8} xs={12}>
          <h3>Fecha del {moment(filter.startDate).format('DD-MM-YYYY')} al {moment(filter.endDate).format('DD-MM-YYYY')}</h3>
        </Col>
      </Row>
      <Tabs
        className="cmt-tabs"
        defaultActiveKey="branchOffice"
        onChange={callback}
      >
        <TabPane tab="Por sucursales" key="branchOffice" />
        <TabPane tab="Por prestaciones" key="prestations" />
        <TabPane tab="Empresa" key="byCompany" />
        <TabPane tab="Resumen" key="summary" />
      </Tabs>
    </PageHeader>
  );
};
export default subHeader;
