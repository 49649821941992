import MonthlyComparative from './MonthlyComparative';
import CallDetails from './CallDetails';
import { Col, Row } from 'antd';


const CallLog = ({ dates }) => {
    return (
        <div>
            <CallDetails dates={dates} />
            <Row gutter={20} style={{ marginTop: 40 }}>
                <Col lg={24}>
                    <MonthlyComparative dates={dates} />
                </Col>
            </Row>

        </div>
    )
}

export default CallLog;