import moment from 'moment';
import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { Row, Col, DatePicker } from 'antd';
import CompaniesWithMoreAbsences from './CompaniesWithMoreAbsences';
import CompaniesWithMostServices from './CompaniesWithMostServices';
import PatientsCountByCompany from './PatientsCountByCompany';

const { RangePicker } = DatePicker;
const cookies = new Cookies();
const startDate = moment().subtract(1, 'months').startOf('month');
const endDate = moment().subtract(1, 'months').endOf('month');

const Resumen = ({ selectedBranchOffices, dates }) => {
	const [selectedCompany, setSelectedCompany] = useState();

	const disabledDate = (current) => {
		return current && current > moment().endOf('day');
	}

	return (
		<div>
			<Col lg={24} xs={24}>
				<PatientsCountByCompany
					dates={dates}
					selectedBranchOffices={selectedBranchOffices}
					selectedCompany={selectedCompany}
				/>
			</Col>
			<Row className="mt-30">
				<Col
					className="custom-right"
					lg={12}
					xs={24}
				>
					<CompaniesWithMostServices
						dates={dates}
						selectedBranchOffices={selectedBranchOffices}
						selectedCompany={selectedCompany}
					/>
				</Col>
				<Col lg={12} xs={24}>
					<CompaniesWithMoreAbsences
						dates={dates}
						selectedBranchOffices={selectedBranchOffices}
						selectedCompany={selectedCompany}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default Resumen;