import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Tag, Card } from 'antd';
import { medicalStaffService } from '../../_services/medicalStaff.service';

const Blocks = ({ doctor, date, selectedBranchOffices, checkedMonth }) => {
  const [blocks, setBlocks] = useState();
  const [loading, setLoading] = useState();
  const [totalPrestations, setTotalPrestations] = useState(0);

  useEffect(() => {

    const params = {
      date: date.format('YYYY-MM-DD'),
      checkedMonth: checkedMonth,
      doctorID: doctor.id,

    }

    if (selectedBranchOffices) {
      params['selectedBranchOffices'] = selectedBranchOffices
    }


    let isSubscribed = true;
    setLoading(true);
    medicalStaffService.getHourlyAttendance(params).then(response => {
      if (isSubscribed) {
        setLoading(false);

        const totalAssitances =  response.hourlyAttendance.map( obj => Number(obj.assistances)).reduce((a, b ) => { return a +b }) ;
        const totalPrestationsBatteries = response.hourlyAttendance.map( obj => Number(obj.prestations_batteries)).reduce( ( a, b ) => { return a + b}) ;
        const totalPrestationsExams =  response.hourlyAttendance.map( obj => Number(obj.prestations_exams)).reduce( ( a, b ) => { return a + b}) ;

        setTotalPrestations( totalAssitances + totalPrestationsBatteries + totalPrestationsExams);

        let total = {
          start: <strong>{  'Total' } </strong>,
          assistances: <strong>{ totalAssitances }</strong>,
          prestations_batteries: <strong>{ totalPrestationsBatteries }</strong>,
          prestations_exams: <strong>{ totalPrestationsExams }</strong>,
        }

        response.hourlyAttendance.push(total);

        setBlocks(response.hourlyAttendance);
      }
    });

    return () => isSubscribed = false;
  }, [date, checkedMonth]);

  // if (!blocks) {
  //   return (<Spin></Spin>)
  // }
  if (totalPrestations > 0) {
    return (
      <Card style={{ marginTop: 10 }} title={`${doctor.first_name} ${doctor.last_name}`}>
        <Spin spinning={loading}>
          <div style={{ width: '100%' }}>
            <Row>
              <Col lg={3}>
                <div style={{ marginTop: 20, color: '#309a80' }}>
                  <strong>Pacientes</strong>
                </div>
                <div style={{ color: '#dc582a' }}>
                  <strong>Baterías</strong>
                </div>
                <div style={{ color: '#dc582a' }}>
                  <strong>Exámenes</strong>
                </div>
              </Col>
              <Col lg={21}>
                <div>
                  {blocks && blocks.map((block, index) =>
                    <div lg={5} key={index} style={{ float: 'left' }}>
                      <Tag>{block.start} </Tag>
                      <div style={{ color: '#309a80', textAlign: 'right', paddingRight: '1.2em' }}>
                        {block.assistances}
                      </div>

                      <div style={{ color: '#dc582a', textAlign: 'right', paddingRight: '1.2em' }}>
                        {block.prestations_batteries}
                      </div>

                      <div style={{ color: '#dc582a', textAlign: 'right', paddingRight: '1.2em' }}>
                        {block.prestations_exams}
                      </div>
                    </div>
                  )}

                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Card>
    )
  } else {
    return (
      <React.Fragment></React.Fragment>
    )
  }
}

export default Blocks;