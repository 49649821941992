import React, { useEffect, useState, useCallback } from 'react';
import { Table, Spin, Card, Popover } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { formatAmount } from '../../_helpers/formatAmount';

const ActivitySummaryTable = ({ prestationsData, loading }) => {

  const columns = [
    {
      title: 'Sucursal',
      dataIndex: 'name',
      key: 'name',
      render: (value, index) => (
        <p 
        className= {`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
          && 'bold'}`}>{value}
        </p>
      )
    },
    {
      title: (
        <strong> Capacidad 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Capacidad total por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'countCapacity',
      key: 'countCapacity',
      align: 'right',
      render: (value, index) => (
        <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1]
          && 'bold'}`}>{formatAmount(value)}
        </p>
      )
    },
    {
      title: (
        <strong> Reservados 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Total de pacientes reservados por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'countPatientsReserved',
      key: 'countPatientsReserved',
      align: 'right',
      render: (value, index) => (
        <p
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
          && 'bold'}`}>{formatAmount(value)}</p>
      )
    },
    {
      title: (
        <strong> Atendidos 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Pacientes admisionados por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'countPatientsAttended',
      key: 'countPatientsAttended',
      align: 'right',
      render: (value, index) => (
        <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
          && 'bold'}`}>{formatAmount(value)}
        </p>
      )
    },
    {
      title: (
        <strong> Libre 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Cupos no reservados
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'availableSpaces',
      key: 'availableSpaces',
      align: 'right',
      render: (value, index) => (
        <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
          && 'bold'}`}>{formatAmount(value)}
        </p>
      )
    },
    {
      title: (
        <strong> Reservas 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Porcentaje de la capacidad reservada por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'reservationPercent',
      key: 'reservationPercent',
      align: 'right',
      render: (value, index) => {
        return  <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
        && 'bold'}`}>
          {formatAmount(Math.round(value*1000)/10)}%
        </p>
      }
    },
    {
      title: (
        <strong> Real 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Porcentaje de reservas admisionadas por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'realPercent',
      key: 'realPercent',
      align: 'right',
      render: (value, index) => {
        return  <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1]
        && 'bold'}`}>
          {formatAmount((value*100).toFixed(1))}%
        </p>
      }
    },
    {
      title: (
        <strong> Ausentismo 
          <Popover 
            trigger="hover"
            overlayClassName="sidebar-tooltip"
            placement="right"
            content={
              <span>
                Porcentaje de reservas no admisionadas por sucursal
              </span>
            }
          >  
            <QuestionCircleOutlined className="question-circle" />
          </Popover>
        </strong>
      ),
      dataIndex: 'attendancePercent',
      key: 'attendancePercent',
      align: 'right',
      render: (value, index) => {
        return  <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
        && 'bold'}`}>
          {value > 1 ? (formatAmount(0)) : formatAmount((100-(value*100)).toFixed(1))}%
        </p>
      }
    },
    {
      title: 'Asistencia',
      dataIndex: 'attendancePercent',
      key: 'attendancePercent',
      align: 'right',
      render: (value, index) => {
        return <p 
        className={`mb-0 ${index === prestationsData.countsByBranchOffices[prestationsData.countsByBranchOffices.length - 1] 
        && 'bold'}`}>
          {formatAmount((value*100).toFixed(1))}%
        </p>
      }
    }
  ];

  return (
    <Card title="Resumen de Actividad" className="mt-40">
      <Spin spinning={loading.batteriesAndExams} className="cmt-spin">
        <Table
          style={{ width: '100%', overflowX: 'auto' }}
          dataSource={prestationsData.countsByBranchOffices}
          columns={columns}
          pagination={false}
          className="cmt-table-list"
        />
      </Spin>
    </Card>
  );

};

export default ActivitySummaryTable;

