import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Spin, Card, Tabs, Table } from 'antd';
import {
    PieChart, Pie, ResponsiveContainer, Cell,
} from 'recharts';
import { callCenterService } from '../../_services/callCenter.service';

const COLORS = ['#5b356d', '#6FB9A7', '#E78B6A', '#E09295', '#E8E281', '#80B7DD'];

const CallDetails = ({ dates }) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Tipo de llamadas',
            dataIndex: 'callend_reason',
            key: 'callend_reason',
            render: (value, record, index) => {
                return <span style={{
                    backgroundColor: COLORS[index],
                    color: 'white',
                    padding: 5,
                    borderRadius: 4
                }}>{value}</span>
            }
        },
        {
            title: 'Cantidad',
            dataIndex: 'count',
            key: 'count'
        },
    ]

    useEffect(() => {
        fetchCallDetails();
    }, [dates])

    const fetchCallDetails = async () => {
        setLoading(true);
        const params = {
            startDate: dates[0].format('YYYY-MM-DD'),
            endDate: dates[1].format('YYYY-MM-DD')
        }
        const response = await callCenterService.getCallsDistribution(params);
        setData(response);
        setChartData(response.map(item => {
            return {
                name: item.callend_reason,
                value: parseInt(item.count)
            }
        }));
        setLoading(false);
    }

    return (
        <Spin spinning={loading}>
            <Row gutter={20}>
                <Col lg={10}>
                    <Card title='Detalle de llamadas'>
                        <Table
                           pagination={false}
                           dataSource={data}
                           columns={columns}
                           className="cmt-table-list" 
                        />
                    </Card>
                </Col>
                <Col lg={14}>
                    <Card title='Distribución de llamadas'>
                        {
                            data && <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                        data={chartData}
                                        innerRadius={60}
                                        outerRadius={100}
                                        paddingAngle={5}
                                        dataKey="value"
                                        label
                                    >
                                        {
                                            data.map((entry, index) => {
                                                return <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]} />
                                            })
                                        }
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </Card>
                </Col>
            </Row>
        </Spin>
    )
}

export default CallDetails;