import React, { useEffect, useState, useCallback } from 'react';
import { Spin, Table } from 'antd';

const qualityTable = ({ actualTab, dataSource, loading }) => {

    const columns = [
        {
            title: 
            (actualTab === "branchOffice") ?
            "Sucursal" : "Prestación",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '12hs o menos',
            dataIndex: 'signed_less_twelve',
            key: 'signed_less_twelve',
            align: 'center',
            width: '14%'
        },
        {
            title: '12hs y 24hs',
            dataIndex: 'signed_more_twelve',
            key: 'signed_more_twelve',
            align: 'center',
            width: '14%'
        },
        {
            title: '24hs y 36hs',
            dataIndex: 'signed_more_twenty_four',
            key: 'signed_more_twenty_four',
            align: 'center',
            width: '14%'
        },
        {
            title: '36hs y 48hs',
            dataIndex: 'signed_more_thirty_six',
            key: 'signed_more_thirty_six',
            align: 'center',
            width: '14%'
        },
        {
            title: '48hs y 72hs',
            dataIndex: 'signed_more_forty_eight',
            key: 'signed_more_seventy_two',
            align: 'center',
            width: '14%'
        },
        {
            title: '72hs o más',
            dataIndex: 'signed_more_seventy_two',
            key: 'signed_more_seventy_two',
            align: 'center',
            width: '14%'
        },
        {
            title: 'Total Informes',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: '15%'
        }
    ];

    return (
      <Spin className="cmt-spin" spinning={loading.table}>
        <Table className="cmt-table-list"
          dataSource={dataSource}
          columns={columns}
          bordered={false}
          locale={{ emptyText: "No hay datos para mostrar" }}
        />
      </Spin>
    )
}
export default qualityTable;