import { Statistic, Row, Col, Spin } from 'antd';
import { formatAmountWithCurrency } from '../../_helpers/formatAmount';

const CapabilityByAmount = ({ reservedAmount, attendedAmount, invoicedAmount, agreementAmount, loading }) => {

	return (
		<div style={{ 'marginTop': 40 }}>
			{
				<Spin spinning={loading.reservedAmount || loading.attendedAmount || loading.invoicedAmount
         || loading.agreementAmount } className="cmt-spin">
					<Row gutter={16}>
						<Col lg={6} xs={24}>
            	<Statistic
								className="cmt-statistic"
								title={
									<div>
										<p className="sub-title">Monto reservado</p>
									</div>
								}
								value={formatAmountWithCurrency(reservedAmount)}
								formatter={(value) => !isNaN(reservedAmount) && ( 
                  <span className='bold'>{value}</span>
								)}
							/>
						</Col>
						<Col lg={6} xs={24} >
							<Statistic
								className="cmt-statistic"
								title={
									<div>
										<p className="sub-title">Monto atendido</p>
									</div>
								}
								value={formatAmountWithCurrency(attendedAmount)}
								formatter={(value) => !isNaN(attendedAmount) && (
									<span className='bold'>{value}</span>
								)}
							/>
						</Col>
						<Col lg={6} xs={24} >
							<Statistic
								className="cmt-statistic"
								title={
									<div>
										<p className="sub-title">Monto facturado</p>
									</div>
								}
								value={formatAmountWithCurrency(invoicedAmount)}
								formatter={(value) => !isNaN(invoicedAmount) && (
									<span className='bold'>{value}</span>
								)}
							/>
						</Col>
						<Col lg={6} >
							<Statistic
								className="cmt-statistic"
								title={
									<div>
										<p className="sub-title">Monto convenio</p>
									</div>
								}
								value={formatAmountWithCurrency(agreementAmount)}
								formatter={(value) => !isNaN(agreementAmount) && (
									<span className='bold'>{value}</span>
								)}
							/>
						</Col>
					</Row>
				</Spin>
			}
		</div>
	)
}

export default CapabilityByAmount;