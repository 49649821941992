import config from '../config';
import authHeader from '../_helpers/auth-header';
import handleResponse from './handle_response';
import { getCookie } from './cookies'

function getQuality(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getQuality`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getPrestationsNames(params) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/getPrestationsNames`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getPatients(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getPatients`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getAttentions(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getAttentions`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getPendingSignature(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getPendingSignature`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getTotalReports(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getTotalReports`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

function getLateReports(params, tab) {
	const requestOptions = {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			...authHeader(),
			country_code: getCookie('country_code')
		}
	};
	const url = new URL(`${config.apiUrl}/prestations/${tab}/getLateReports`);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
	return fetch(url, requestOptions).then(handleResponse);
}

export const qualityService = {
	getQuality,
	getPrestationsNames,
	getPatients,
	getAttentions,
	getPendingSignature,
	getTotalReports,
	getLateReports,
};