import React, { useEffect, useState, useRef } from 'react';
import { Spin, Card, Row, Col, Modal, Table } from 'antd';
import { hallwayService } from '../../_services/hallway.service';
import { filter, groupBy, invert } from 'lodash';

const TIMEOUT = 15000;

const Exams = ({ date, selectedBranchOffice }) => {
const columns = [
   {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
   },
   {
      title: 'Nombres',
      dataIndex: 'names',
      key: 'names',
   },
   {
      title: 'Apellidos',
      dataIndex: 'last_name',
      key: 'last_name',
   },
   {
      title: 'Exámen',
      dataIndex: 'name',
      key: 'name'
   },
   {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state'
   }
];

const [visibleModal, setVisibleModal] = useState(false);
const boxNames = {
   0: 'Enfermería',
   1: 'Optometría',
   2: 'Audiometría',
   3: 'Psicología',
   4: 'Espirometría',
   5: 'Rayos',
   6: 'Test de drogas',
   7: 'Externo',
   8: 'Laboratorio',
   9: 'Medical',
   10: 'Electrocardiograma',
   null: 'No definido'
}
const [exams, setExams] = useState();
const [boxTypeCounter, setBoxTypeCounter] = useState();
const [patientsToShowOnModal, setPatientsToShowOnModal] = useState([]);
const intervalRef = useRef(null)

useEffect(() => {
   fetchExams()
   if (!intervalRef.current) { 
      intervalRef.current = setInterval(async () => {
      await fetchExams()
   }, TIMEOUT);
   }
   return () => {
      if(intervalRef.current) {
         clearInterval(intervalRef.current)
         intervalRef.current = null
      }
   };
}, [selectedBranchOffice])

const handleCancel = () => {
   setVisibleModal(false);
}

const handleOk = () => {
   setVisibleModal(false);
}

const showModal = (box, state) => {
   setVisibleModal(true);
   const boxNamesBy = invert(boxNames);
   const boxType = box.name == 'No definido' ? null : Number(boxNamesBy[box.name]);
   setPatientsToShowOnModal(exams.exams.filter(obj => obj.state == state).filter(obj => obj.box_type == boxType));
}

const getParams = () => {
   const params = {
      date: date.format('YYYY-MM-DD')
   }

   if (selectedBranchOffice) {
      params['selectedBranchOffice'] = selectedBranchOffice
   }

   return params;
}

const [loadingExams, setLoadingExams] = useState(false);

const fetchExams = async () => {
   setLoadingExams(true);
   const response = await hallwayService.getExams(getParams());
   setExams(response);

   const exams = groupBy(response.exams, "box_type");
   const data = Object.keys(exams).map(examName => {
      const createdCount = groupBy(filter(exams[examName], (item) => {
      return item.state == "created"
      }), 'rut')

      let doneCount = groupBy(exams[examName], 'rut');
      doneCount = Object.keys(doneCount).map(rut => {
      var state = 'created';
      var states = doneCount[rut].map(i => i.state);
      if (states.length == states.filter(i => i == "done").length) {
         var state = 'done';
      }
      return {
         rut: rut,
         state: state
      }
      }).filter(i => i.state == 'done');

      return {
      name: boxNames[examName],
      created: Object.keys(createdCount).length,
      done: doneCount.length
      }
   });

   setBoxTypeCounter(data);
   setLoadingExams(false);
}
return (
   <div>
      <Modal
      title="Exámenes"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      >
         <Table className="cmt-table-list" dataSource={patientsToShowOnModal} columns={columns} />
      </Modal>

      <Card title={<strong>Exámenes</strong>}>
      {
         <Spin spinning={loadingExams}>
            <Row gutter={12}>
            {boxTypeCounter && boxTypeCounter.map((obj, index) => {
               return <Col lg={8} key={index}>
                  <Card
                  title={<strong>{obj.name}</strong>}
                  style={{ marginBottom: 20, background: '#ecf0f1', }}>
                  <Row>
                     <Col lg={12}>
                        <Card style={{
                        color: '#DD5E32',
                        cursor: 'pointer'
                        }}
                        onClick={() => {
                           showModal(obj, 'created')
                        }}>
                        <Row>
                           <Col style={{ textTransform: 'uppercase', fontSize: '1.1em' }}>
                              en espera
                           </Col>
                        </Row>
                        <Row>
                           <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                              {obj['created']}
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              pacientes
                        </Col>
                        </Row>
                        </Card>
                     </Col>

                     <Col lg={12}>
                        <Card style={{
                        color: '#5FB09C',
                        cursor: 'pointer',
                        width: '150px'
                        }}
                        onClick={() => {
                           showModal(obj, 'done')
                        }}>
                        <Row>
                           <Col style={{ textTransform: 'uppercase', fontSize: '1.1em'}}>
                              atendidos
                           </Col>
                        </Row>
                        <Row>
                           <Col style={{ textAlign: 'center', fontSize: '1.6em' }}>
                              {obj['done']}
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              pacientes
                           </Col>
                        </Row>
                        </Card>
                     </Col>
                  </Row>
                  </Card>
               </Col>
            })}
            </Row>
         </Spin>
      }
      </Card>
   </div>
)
}

export default Exams;