import { debounce } from 'lodash';
import { dashboardService } from '../_services/dashboard.service';

const fetchCompanyNames = debounce(async (value) => {
  try {
    const response = value ? await dashboardService.getCompanies({ query: value })
      : [];
    return response;
  } catch (error) {
    console.error(error);
    message.error('Ha ocurrido un error al buscar la información de empresas');
  }
}, 400);

export default fetchCompanyNames;