import React, { useEffect, useState } from 'react';
import { Spin, Card } from 'antd';
import {
    YAxis,
    Legend,
    CartesianGrid,
    ComposedChart,
    ResponsiveContainer,
    Tooltip,
    Bar,
    LabelList,
    XAxis
} from 'recharts';
import { callCenterService } from '../../_services/callCenter.service';

const MonthlyComparative = ({ dates }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        fetchMonthlyComparative();
    }, [dates]);

    const fetchMonthlyComparative = async () => {
        setLoading(true);
        const params = {
            startDate: dates[0].format('YYYY-MM-DD'),
            endDate: dates[1].format('YYYY-MM-DD')
        }
        const response = await callCenterService.getMonthlyComparative(params);
        setData(response.map(item => {
            return {
                day: `${item.year}-${item.month}`,
                inputs: item.inputs,
                outputs: item.outputs
            }
        }));
        setLoading(false);
    }

    return (
        <Card title='Comparativo mensual'>
            <Spin spinning={loading}>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        title="Comparativo"
                        data={data}
                        style={{ marginTop: '100px', 'width': '100%' }}
                    >
                        <Legend
                            verticalAlign="top"
                            align="center"
                            layout='vertical'
                        />
                        <CartesianGrid vertical={false} />
                        <YAxis
                            yAxisId="left"
                            interval="preserveStartEnd"
                            tickLine={false}
                            axisLine={false}
                        />
                        <Tooltip />
                        <Bar
                            yAxisId="left"
                            type="monotone"
                            dataKey="inputs"
                            name="LLamadas recibidas"
                            fill="#5b356d"
                            legendType='circle'
                        >
                        </Bar>
                        <Bar
                            yAxisId="left"
                            type="monotone"
                            dataKey="outputs"
                            name="LLamadas salientes"
                            fill="#82c2b3"
                            legendType='circle'
                        >
                        </Bar>

                        <XAxis dataKey="day" />
                    </ComposedChart>
                </ResponsiveContainer>
            </Spin>
        </Card>
    )
}

export default MonthlyComparative;